/* eslint-disable */
import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useParams, useNavigate } from 'react-router-dom';

import { add, getUserDetailsById } from 'actions/admin/userAddress';
import Autocomplete from 'react-google-autocomplete';
import { GOOGLE_MAPS_API_KEY } from '../../../config';
import { setGoogleAddressFormData } from 'actions/commonActions';
import 'react-phone-number-input/style.css';
import PhoneInput, { getCountryCallingCode } from 'react-phone-number-input';
import extractNumber from 'utils/extractNumber';
import { getCountryShort } from 'utils/countryCode';

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';

import Errors from 'views/Notifications/Errors';
import Spinner from 'views/Spinner';

const AddAddres = ({
  add,
  history,
  match,
  errorList,
  loading,
  getUserDetailsById,
  currentUserDetails,
  googleAddressFormData,
  setGoogleAddressFormData,
}) => {
  const { customerName: customerName, customer_id: user_id } = useParams();
  /**************** use state for form data *********************/
  let navigate = useNavigate();
  const [defaultCountry, setDefaultCountry] = useState('US');
  const [autocompleteValue, setAutocompleteValue] = useState('');
  const [defaultCountryCode, setDefaultCountryCode] = useState('+1');
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    // ccode: '+1',
    contact_no: '',
    iute164_phone: '',
    address: '',
    address_line_1: '',
    address_line_2: '',
    latitute: 0,
    longitude: 0,
    city: '',
    zipcode: '',
    country: '',
    state: '',
    google_id: '',
    google_place_id: '',
    country_code: '',
    // location: geometry,
  });

  useEffect(() => {
    const fetchDefaultCountryCode = async () => {
      try {
        const response = await fetch('https://geolocation-db.com/json/');
        const data = await response.json();
        const countryCode = data.country_code || '';

        // Use libphonenumber-js to format the country code
        const phoneNumber = getCountryCallingCode(countryCode);


        if (phoneNumber) {
          setDefaultCountry(countryCode);
          setFormData({ ...formData, ccode: `+${phoneNumber}` });
          setDefaultCountryCode(`+${phoneNumber}`);

        }
      } catch (error) {
        console.error('Error fetching default country code:', error);
      }
    };

    fetchDefaultCountryCode();
  }, []);


  /**************** expload form data *********************/
  const {
    address,
    address_line_1,
    address_line_2,
    name,
    email,
    // ccode,
    latitute,
    longitude,
    contact_no,
    iute164_phone,
    city,
    zipcode,
    country,
    state,
    // location: geometry,
  } = formData;

  // to resolve useEffect dependency error

  /*************************get dealer details********************/
  useMemo(() => {
    getUserDetailsById(user_id);
  }, [user_id]);



  /*************************set form data********************/
  useMemo(() => {
    console.log(currentUserDetails);
    if (Object.keys(currentUserDetails).length > 0) {
      setFormData({
        ...formData,
        name: currentUserDetails.name ? currentUserDetails.name : '',
        email: currentUserDetails.email ? currentUserDetails.email : '',
      });
    }
  }, [currentUserDetails]);

  /**************** dispatch to remove error *********************/

  /**************** on Change event *********************/
  const onChange = (e) => {
    if (e.target.name === 'name') {
      setFormData({ ...formData, name: e.target.value.replace(/[&#<>@]/g, '') })
    } else {
      // setFormData({ ...formData, [e.target.name]: e.target.value });
      const newFormData = { ...formData, [e.target.name]: e.target.value.trimLeft() };
      newFormData['address'] = `${newFormData.address_line_1} ${newFormData.address_line_2 ? newFormData.address_line_2 + ',' : ''
        } ${newFormData.city} ${newFormData.state} ${newFormData.zipcode} ${newFormData.country
        }`;
      setFormData(newFormData);
    }
  };

  //########################## change event for phone number ##############################
  const onPhoneChange = (number) => {
    const contact_no = extractNumber(number, defaultCountryCode);
    setFormData((form) => ({
      ...form,
      contact_no: contact_no,
      iute164_phone: number ?? '',
    }));
  };

  const onCountryChange = (code) => {
    setFormData((form) => ({
      ...form,
      ccode: '+' + getCountryCallingCode(code ?? getCountryShort(defaultCountryCode)),
    }));
    setDefaultCountryCode('+' + getCountryCallingCode(code ?? getCountryShort(defaultCountryCode)));
  };

  /**************** on submit event *********************/
  const onSubmit = (e) => {
    e.preventDefault();
    add({ ...formData, ccode: defaultCountryCode }, customerName, user_id, navigate);
  };

  // to resolve useEffect dependency error

  //#################### update formdata after changes in google address  #################
  useEffect(() => {
    if (Object.keys(googleAddressFormData).length > 0) {
      setFormData({
        ...formData,
        name: googleAddressFormData.name,
        email: googleAddressFormData.email,
      });
    }
    /*dispatch({
      type: SET_GOOGLE_ADDRESS_FORM_DATA,
      payload: {},
    });*/
  }, [address]);

  const setGMapPlace = (location) => {

    const google_id = location.id;
    const google_place_id = location.place_id;
    const address = location.formatted_address;
    const addresses = location.address_components;
    let latitute = location?.geometry?.location?.lat((e) => e.Scopes[0].e);
    let longitude = location?.geometry?.location?.lng((e) => e.Scopes[0].f);

    let city = '';
    let state = '';
    let address_line_1 = '';
    let address_line_2 = '';
    let country = '';
    let country_code = '';
    let zipcode = '';
    if (addresses)
      addresses.forEach((e) => {
        switch (e.types[0]) {
          case 'street_number':
          case 'route':
            address_line_1 += (address_line_1 ? ' ' : '') + e.long_name;

            break;
          case 'sublocality_level_2':
          case 'sublocality_level_1':
            address_line_2 += (address_line_2 ? ' ' : '') + e.long_name;
            break;
          case 'locality':
          case 'sublocality_level_1':
          case 'administrative_area_level_3':
          case 'administrative_area_level_2':
          case 'postal_town':
            if (city == '') {
              city = e.long_name;
            }
            break;
          case 'administrative_area_level_1':
            state = e.long_name;
            break;
          case 'postal_code':
          case 'postal_code_prefix':
            zipcode = e.long_name;
            break;
          case 'country':
            country = e.long_name;
            country_code = e.short_name;
            break;
          default:
            break;
        }
      });
    setFormData({
      ...formData,
      address,
      address_line_1,
      address_line_2,
      latitute,
      longitude,
      city,
      google_id,
      google_place_id,
      state,
      country,
      country_code,
      zipcode,
      // location: geometry,
    });
    setAutocompleteValue('');
  };
  const handleInputChange = (event) => {
    setAutocompleteValue(event.target.value);
  };

  return (
    <div className="animated fadeIn">
      {loading ? (
        <Spinner />
      ) : (
        <Form
          className="form-horizontal"
          // autoComplete="false"
          onSubmit={(e) => onSubmit(e)}
        >
          <Row>
            <Col xs="12" sm="12">
              <Card className="mb0">
                <CardHeader>
                  <h5>Address Information</h5>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="name">
                          Name <span>*</span>
                        </Label>
                        <Input
                          type="text"
                          id="name"
                          name="name"
                          value={name}
                          required
                          onChange={(e) => onChange(e)}
                          invalid={errorList.name ? true : false}
                        />
                        <Errors current_key="name" key="name" />
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="address">Search Your Address</Label>
                        <Autocomplete
                          apiKey={GOOGLE_MAPS_API_KEY}
                          onPlaceSelected={(place) => {
                            {
                              setGMapPlace(place);
                            }
                          }}
                          options={{
                            types: ['address'],
                          }}
                          value={autocompleteValue}
                          onChange={handleInputChange}
                          className="form-control"
                          onFocus={() => setGoogleAddressFormData(formData)}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="address">
                          Address <span>*</span>
                        </Label>
                        <Input
                          type="textarea"
                          id="address"
                          name="address"
                          value={address}
                          required
                          onChange={(e) => onChange(e)}
                          invalid={errorList.address ? true : false}
                        />
                        <Errors current_key="address" key="address" />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label htmlFor="address">
                          Address Line 1<span>*</span>
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="address_line_1"
                          name="address_line_1"
                          placeholder="Address Line 1 *"
                          maxLength="100"
                          value={address_line_1}
                          required
                          onChange={(e) => onChange(e)}
                          invalid={errorList.address_line_1 ? true : false}
                        />
                        <Errors
                          current_key="address_line_1"
                          key="address_line_1"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label htmlFor="address">Address Line 2</Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="address_line_2"
                          name="address_line_2"
                          placeholder="Address Line 2"
                          maxLength="100"
                          value={address_line_2}
                          onChange={(e) => onChange(e)}
                          invalid={errorList.address_line_2 ? true : false}
                        />
                        <Errors
                          current_key="address_line_2"
                          key="address_line_2"
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="country">
                          Country <span>*</span>
                        </Label>
                        <Input
                          type="text"
                          name="country"
                          id="country"
                          value={country}
                          disabled={true}
                          required
                          invalid={errorList.country ? true : false}
                        ></Input>
                        <Errors current_key="country" key="country" />
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="state">State</Label>
                        <Input
                          type="text"
                          name="state"
                          id="state"
                          value={state}
                          disabled={true}
                          invalid={errorList.state ? true : false}
                        />
                        <Errors current_key="state" key="state" />
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="city">
                          City <span>*</span>
                        </Label>
                        <Input
                          type="text"
                          id="city"
                          name="city"
                          maxLength="100"
                          value={city}
                          disabled={true}
                          required
                          invalid={errorList.city ? true : false}
                        />
                        <Errors current_key="city" key="city" />
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="zipcode">
                          Zipcode <span>*</span>
                        </Label>
                        <Input
                          type="text"
                          id="zipcode"
                          name="zipcode"
                          maxLength="10"
                          // minLength="3"
                          value={zipcode}
                          required
                          onChange={(e) => onChange(e)}
                          invalid={errorList.zipcode ? true : false}
                        />
                        <Errors current_key="zipcode" key="zipcode" />
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="email">
                          Email <span>*</span>
                        </Label>
                        <Input
                          type="text"
                          id="email"
                          name="email"
                          value={email}
                          required
                          onChange={(e) => onChange(e)}
                          invalid={errorList.email ? true : false}
                        />
                        <Errors current_key="email" key="email" />
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="contact_no">
                          Contact No <span>*</span>
                        </Label>
                        {/* <Input
                          type="contact_no"
                          id="contact_no"
                          name="contact_no"
                          maxLength={14}
                          value={contact_no}
                          required
                          onChange={(e) => onChange(e)}
                          invalid={errorList.contact_no ? true : false}
                        /> */}
                        <PhoneInput
                          className=""
                          autoComplete="tel"
                          withCountryCallingCode={true}
                          countryCallingCodeEditable={false}
                          smartCaret={true}
                          international={true}
                          defaultCountry={defaultCountry}
                          value={iute164_phone}
                          name="contact_no"
                          addInternationalOption={false}
                          onChange={onPhoneChange}
                          onCountryChange={onCountryChange}
                        />
                        <Errors current_key="contact_no" key="contact_no" />
                      </FormGroup>
                    </Col>
                  </Row>

                  {/* <GMaps
                    label="Location"
                    onChange={setGMapPlace}
                    placeholder={'Search your address'}
                    types={'address'}
                    formData={formData}
                  /> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <CardFooter>
                  <Button
                    className="mr-05"
                    type="submit"
                    size="sm"
                    color="primary"
                    disabled={loading}
                  >
                    <i className="fa fa-dot-circle-o"></i> Submit
                  </Button>
                  <Link
                    to={`/admin/customers/customer-address/${customerName}/${user_id}`}
                  >
                    <Button type="reset" size="sm" color="danger">
                      <i className="fa fa-ban"></i> Cancel
                    </Button>
                  </Link>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Form>
      )}
    </div>
  );
};

AddAddres.propTypes = {
  add: PropTypes.func.isRequired,
  errorList: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  getUserDetailsById: PropTypes.func.isRequired,
  currentUserDetails: PropTypes.object.isRequired,
  googleAddressFormData: PropTypes.object.isRequired,
  setGoogleAddressFormData: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  loading: state.userAddress.loading,
  errorList: state.errors,
  currentUserDetails: state.userAddress.currentUserDetails,
  googleAddressFormData: state.commonActions.googleAddressFormData,
});

export default connect(mapStateToProps, {
  add,
  getUserDetailsById,
  setGoogleAddressFormData,
})(AddAddres);
