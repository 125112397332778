/* eslint-disable */
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useMatch, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Button,
  Card,
  CardBody,
  Col,
  Row,
  CardFooter,
  CardHeader,
  Tooltip,
  Label,
  Form,
  FormGroup,
  Input,
} from 'reactstrap';
import Moment from 'react-moment';
import Spinner from 'views/Spinner';
import {
  USER_ROLE,
  ORDER_LIST_PAYMENT_STATUS,
  VIEW_ORDER_LIST_ORDER_STATUS,
  ORDER_LIST_ORDER_STATUS,
} from 'constants/index';
import * as Constants from 'constants/index';
import {
  getOrderById,
  deleteBuildShopNotes,
  changeStatus,
  changeOrderStatus,
} from 'actions/admin/order';
import { getProductById } from 'actions/admin/product';
import { convertCarrierTypes, convertServiceTypes } from 'actions/commonActions';
import { CgTrack } from 'react-icons/cg';
import { AiFillPrinter } from 'react-icons/ai';
import InternalNotes from 'views/Admin/Orders/InternalNotes';
import BuildShopNotes from 'views/Admin/Orders/BuildShopNotes';
import AddressFormat from './Components/AddressFormat';
import { amountDue, productTotal, totalBeforeTax } from './Components/OrderCalculation';

const ViewOrderDetails = ({
  getOrderById,
  // match,
  currentOrder,
  loading,
  auth: { user },
  deleteBuildShopNotes,
  changeStatus,
  changeOrderStatus,
  getProductById,
}) => {
  const match = useMatch('/admin/orders/details/:order_id');

  const [labelToolTip, setlabelToolTip] = useState(false);
  const [trackToolTip, settrackToolTip] = useState(false);
  const navigate = useNavigate();
  //################## Get Details of order by id ###################
  const [orderDetailsBorder, setorderDetailsBorder] = useState('');
  useMemo(() => {
    getOrderById(match.params.order_id);
    {
      currentOrder.products && currentOrder.products.length > 1
        ? setorderDetailsBorder('border_bottom')
        : null;
    }
  }, [getOrderById, match.params.order_id]);

  const [buildShopNoteEdit, setBuildShopNoteEdit] = useState(false);
  const handleEditBuildShopNotes = () => {
    setBuildShopNoteEdit(true);
    //getOrderById(order_id);
  };

  const getUpdatedOrder = async () => {
    await getOrderById(match.params.order_id);
  };

  const handleDeleteBuildShopNotes = (order_id, product_id) => {
    deleteBuildShopNotes(order_id, product_id);
    getOrderById(order_id);
  };

  const [checkBuildShopNotes, setCheckBuildShopNotes] = useState(false);

  const handleChageOrderStatus = async (order_id) => {
    {
      let checkBuildNote = true;
      const checkFittingOptions = currentOrder?.products?.map(product => product?.fitting_options?.length > 0)
      if (!checkFittingOptions.includes(false)) {
        currentOrder &&
          currentOrder.products.map((item) =>
            item.build_shop_notes && item.build_shop_notes != ''
              ? (checkBuildNote = true)
              : (checkBuildNote = false)
          );
      }
      //setCheckBuildShopNotes(checkBuildNote);
      if (checkBuildNote == false && !checkFittingOptions.includes(false)) {
        alert(
          'Please make sure that you have entered notes for all customized shaft.'
        );
        return false;
      } else {
        let statusFromData = {
          order_id: order_id,
          previous_order_status: 6,
          new_order_status: 5,
          refunded_amount: 0,
        };
        const response = await changeOrderStatus(statusFromData, history);
        if (response.data.status) {
          navigate('/admin/orders');
        }

      }
    }
  };
  let currentOrderSort = currentOrder?.products?.sort(item => item?.build_shop_notes == (undefined || null) ? 1 : -1);
  return loading ? (
    <Spinner />
  ) : (
    <div className="viewOrderDetailstab">
      {Object.keys(currentOrder).length > 0 ? (<Row>
        <Col md="12">
          <Card>
            <CardHeader>
              {user &&
                user?.isStaff &&
                user?.permission.includes(
                  Constants.PERMISSION_NAME.BUILD_SHOP_MANAGEMENT
                ) && (
                  <>
                    <Button
                      type="button"
                      size="sm"
                      color="primary"
                      onClick={() => handleChageOrderStatus(currentOrder._id)}
                    >
                      Mark As Done
                    </Button>
                    &nbsp;
                  </>
                )}

              {/* {user &&
                user?.isStaff &&
                user?.permission.includes(
                  Constants.PERMISSION_NAME.SHIPPING_MANAGEMENT
                ) && (
                  <>
                    <a
                      href={`/admin/orders/print-label/${match.params.order_id}`}
                      rel="noopener noreferrer"
                    >
                      <Button
                        type="button"
                        size="sm"
                        color="primary"
                        title="Print Ship Label"
                      >
                        Print Ship Label
                      </Button>
                    </a>
                    &nbsp;
                  </>
                )} */}

              <Button
                onClick={() => navigate(-1)}
                type="reset"
                size="sm"
                color="danger"
              >
                <i className="fa fa-arrow-left"></i> Back
              </Button>
            </CardHeader>
          </Card>
        </Col>
        <Col sm="12">
          <Card>
            <CardBody className="label-tag-header text-center">
              <Label>
                {currentOrder.order_id}
                &nbsp; &nbsp; (
                <span className="time_formatting">
                  <Moment
                    format={
                      process.env.REACT_APP_DISPLAY_DATE_FORMATE ?? 'MM/DD/YYYY'
                    }
                    locale="de"
                  >
                    {currentOrder.ordered_on}
                  </Moment>
                </span>
                <span className="time_formatting">
                  <Moment format="HH:mm" locale="de">{currentOrder.ordered_on}</Moment>
                </span>
                )
              </Label>
              {currentOrder.tracking_url && (
                <a
                  href={currentOrder.tracking_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button type="button" size="sm" color="info">
                    <i className="fa fa-truck" id="trackOrder"></i>
                    <Tooltip
                      placement="top"
                      isOpen={trackToolTip}
                      target="trackOrder"
                      toggle={() => settrackToolTip(!trackToolTip)}
                    >
                      Track Order
                    </Tooltip>
                  </Button>
                </a>
              )}{' '}
              &nbsp;
            </CardBody>
          </Card>
        </Col>
        <Col md="12">
          <Card>
            <CardHeader className="label-tag-header">
              Customer Details
            </CardHeader>
            <CardBody>
              <p>
                <Label className="label-tag">{currentOrder.user_name} </Label>(
                {USER_ROLE[currentOrder.user_type]})
              </p>

              {currentOrder.payment_order_id && (
                <p>
                  <Label className="label-tag">Payment ID</Label>(
                  {currentOrder.payment_order_id} )
                </p>
              )}
              {(user && user?.isAdmin) ||
                (user?.isStaff &&
                  user?.permission.includes(
                    Constants.PERMISSION_NAME.ORDER_MANAGEMENT
                  )) ||
                (user?.isStaff &&
                  user?.permission.includes(
                    Constants.PERMISSION_NAME.SHIPPING_MANAGEMENT
                  )) ? (
                <>
                  <Row>
                    <Col md={currentOrder?.mergeShippingOrder?.order_id ? 4 : 6}>
                      <p>
                        <Label className="label-tag">Order Status</Label>
                        {VIEW_ORDER_LIST_ORDER_STATUS[currentOrder.order_status]}
                      </p>
                    </Col>
                    <Col md={currentOrder?.mergeShippingOrder?.order_id ? 4 : 6}>
                      <p>
                        <Label className="label-tag">Payment Status</Label>
                        {ORDER_LIST_PAYMENT_STATUS[currentOrder.payment_status]}
                      </p>
                    </Col>
                    {currentOrder?.mergeShippingOrder?.order_id && <Col md="4">
                      <p>
                        <Label className="label-tag">Merged With Order Id</Label>
                        {currentOrder?.mergeShippingOrder?.order_id}
                      </p>
                    </Col>}
                  </Row>
                  <hr />
                  <Row>
                    <Col md="6">
                      <div>
                        <Label className="label-tag-header">
                          Billing Address
                        </Label>
                        <p className="data-ptag">
                          <Label className="label-tag">Name :</Label>
                          {currentOrder.billing_address.name}
                        </p>
                        <p className="data-ptag">
                          <Label className="label-tag">Email :</Label>
                          {currentOrder.billing_address.email}
                        </p>
                        <p className="data-ptag">
                          <Label className="label-tag">Contact No :</Label>
                          {currentOrder.billing_address.ccode &&
                            currentOrder.billing_address.ccode}
                          {currentOrder.billing_address.contact_no}
                        </p>
                        <AddressFormat
                          address={
                            {
                              addressData: currentOrder?.billing_address,
                              city: currentOrder?.billing_city,
                              state: currentOrder?.billing_state,
                              zipcode: currentOrder?.billing_zipcode,
                              country: currentOrder?.billing_country
                            }
                          }
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div>
                        <Label className="label-tag-header">
                          Shipping Address
                        </Label>
                        <p className="data-ptag">
                          <Label className="label-tag">Name :</Label>
                          {currentOrder.shipping_address.name}
                        </p>
                        <p className="data-ptag">
                          <Label className="label-tag">Email :</Label>
                          {currentOrder.shipping_address.email}
                        </p>
                        <p className="data-ptag">
                          <Label className="label-tag">Contact No :</Label>
                          {currentOrder.shipping_address.ccode &&
                            currentOrder.shipping_address.ccode}
                          {currentOrder.shipping_address.contact_no}
                        </p>
                        <AddressFormat
                          address={
                            {
                              addressData: currentOrder?.shipping_address,
                              city: currentOrder?.shipping_city,
                              state: currentOrder?.shipping_state,
                              zipcode: currentOrder?.shipping_zipcode,
                              country: currentOrder?.shipping_country
                            }
                          }
                        />
                      </div>
                    </Col>
                    {currentOrder.delivery_remark && <>
                      <hr className='mt-3' />
                      <Col md="12">
                        <div>
                          <Label className="label-tag-header">
                            Delivery Remark
                          </Label>
                          <p className="data-ptag">
                            <Label className="label-tag">Remark :</Label>
                            {currentOrder.delivery_remark}
                          </p>
                        </div>
                      </Col>
                    </>}
                  </Row>
                </>
              ) : (
                ''
              )}
            </CardBody>
          </Card>
          {(currentOrder?.order_category !== "service_order") && <Card>
            <CardHeader className="label-tag-header">Order Details</CardHeader>
            <CardBody>
              {currentOrderSort.map((item, index) => (
                <>
                  <Row className="col-bottom">
                    <Col md="4">
                      <Col>
                        <Label className="label-tag-header">
                          {item.product_title} ({item.category_name[0]})
                        </Label>
                        <div>
                          <Label className="label-tag"> SKU :</Label> {item.sku}
                        </div>
                        <div>
                          <Label className="label-tag"> Quantity :</Label>{' '}
                          {item.quantity}
                        </div>
                        {(user && user?.isAdmin) ||
                          (user?.isStaff &&
                            user?.permission.includes(
                              Constants.PERMISSION_NAME.ORDER_MANAGEMENT
                            )) ||
                          (user?.isStaff &&
                            user?.permission.includes(
                              Constants.PERMISSION_NAME.SHIPPING_MANAGEMENT
                            )) ? (
                          <>
                            <div>
                              <Label className="label-tag">
                                {' '}
                                Product Price :
                              </Label>{' '}
                              $
                              {Number(
                                item.product_price['$numberDecimal'] * Number(item.quantity)
                              ).toFixed(2)}
                            </div>
                          </>
                        ) : (
                          ''
                        )}
                        {Object.keys(item.product_variation).length > 0 && (
                          <div>
                            {/* <p className="data-ptag">
                          <Label className="label-tag">Variation :</Label>
                        </p> */}
                            {Object.entries(item.product_variation).map(
                              ([key, value]) => (
                                <>
                                  <div>
                                    <Label className="label-tag">
                                      {key && key == 'Colors' ? 'Color' : key} :
                                    </Label>
                                    {value}
                                  </div>
                                  {/* <p className="option-ptag">
                              <Label className="attribute-title">{key} :</Label>
                              {value}
                              </p> */}
                                </>
                              )
                            )}
                          </div>
                        )}

                        {item.fitting_options && item.fitting_options.length > 0
                          ? item.fitting_options.map((value) => {
                            if (value.attribute_name == 'Playing Length') {
                              return (
                                <>
                                  <div>
                                    <Label className="label-tag">
                                      {' '}
                                      Playing Length :
                                    </Label>{' '}
                                    {(value?.attribute_value == 0) ? "Uncut" : value?.attribute_value}
                                  </div>
                                </>
                              );
                            }

                            if (value.attribute_name == 'Extra Wrap') {
                              return (
                                <>
                                  <div>
                                    <Label className="label-tag">
                                      {' '}
                                      Extra Wraps :
                                    </Label>
                                    {value.attribute_value &&
                                      value.attribute_value == 1
                                      ? 'None'
                                      : value.attribute_value == 2
                                        ? '1'
                                        : value.attribute_value == 3
                                          ? '2'
                                          : 'None'}
                                  </div>
                                </>
                              );
                            }
                            if (value.attribute_name == 'Grip Logo') {
                              return (
                                <>
                                  <div>
                                    <Label className="label-tag">
                                      {' '}
                                      Grip Logo :
                                    </Label>
                                    {value.attribute_value &&
                                      value.attribute_value}
                                  </div>
                                </>
                              );
                            }

                            if (value.attribute_name == 'Adaptors') {
                              return (
                                <>
                                  <div>
                                    <Label className="label-tag">
                                      {' '}
                                      Adaptor :
                                    </Label>{' '}
                                    {value.option_name} (${value.price})
                                  </div>
                                  <div>
                                    <Label className="label-tag">
                                      {' '}
                                      Adaptor Setting :{' '}
                                    </Label>
                                    {value.sub_option_name}
                                  </div>
                                </>
                              );
                            }
                            if (value.attribute_name == 'Grips') {
                              return (
                                <>
                                  <div>
                                    <Label className="label-tag">
                                      {' '}
                                      Grips Brand :{' '}
                                    </Label>
                                    {value.option_name}
                                  </div>
                                  <div>
                                    <Label className="label-tag">
                                      {' '}
                                      Grip Model :{' '}
                                    </Label>
                                    {value.sub_option_name} (${value.price})
                                  </div>
                                </>
                              );
                            }
                          })
                          : null}
                      </Col>
                    </Col>
                    <Col md="4">
                      {item.fitting_options &&
                        item.fitting_options.length > 0 ? (
                        <>
                          <Label className="label-tag-header">
                            Customization Notes
                          </Label>
                          <div>
                            {item.fitting_options.map((value) => {
                              if (
                                value.attribute_name == 'Product Customization'
                              ) {
                                return (
                                  <>
                                    <div>{value.attribute_value}</div>
                                  </>
                                );
                              }
                            })}
                          </div>
                        </>
                      ) : (
                        <>
                          <Label className="label-tag-header">
                            Customization Notes
                          </Label>
                          <div>NA</div>
                        </>
                      )}
                    </Col>
                    <Col md="4">
                      {item.fitting_options &&
                        item.fitting_options.length > 0 ? (
                        <>
                          <Label className="label-tag-header">
                            Build Shop Notes
                            {user?.isStaff &&
                              user?.permission.includes(
                                Constants.PERMISSION_NAME.BUILD_SHOP_MANAGEMENT
                              ) &&
                              item.build_shop_notes &&
                              item.build_shop_notes != null ? (
                              <>
                                <i
                                  className="fa fa-pencil"
                                  onClick={() => handleEditBuildShopNotes()}
                                ></i>{' '}
                                <i
                                  className="fa fa-trash"
                                  onClick={() =>
                                    handleDeleteBuildShopNotes(
                                      currentOrder._id,
                                      item._id
                                    )
                                  }
                                ></i>
                              </>
                            ) : (
                              ''
                            )}
                          </Label>
                          <div>
                            {!buildShopNoteEdit &&
                              item.build_shop_notes &&
                              item.build_shop_notes != null ? (
                              <p>{item.build_shop_notes}</p>
                            ) : (
                              <>
                                {user?.isStaff &&
                                  user?.permission.includes(
                                    Constants.PERMISSION_NAME
                                      .BUILD_SHOP_MANAGEMENT
                                  ) && (
                                    <>
                                      <BuildShopNotes
                                        order_id={currentOrder._id}
                                        product_id={item._id}
                                        index={index}
                                        notesValue={item.build_shop_notes}
                                        buildShopNoteEdit={buildShopNoteEdit}
                                        setBuildShopNoteEdit={
                                          setBuildShopNoteEdit
                                        }
                                        getUpdatedOrder={getUpdatedOrder}
                                      ></BuildShopNotes>
                                    </>
                                  )}
                              </>
                            )}
                          </div>
                        </>
                      ) : (
                        <>
                          <Label className="label-tag-header">
                            Build Shop Notes
                          </Label>
                          <div>NA</div>
                        </>
                      )}
                    </Col>
                  </Row>
                </>
              ))}
            </CardBody>
          </Card>}
          {currentOrder?.buildShopSupplyProduct.length > 0 && <Card>
            <CardHeader className="label-tag-header">Build Shop Supply</CardHeader>
            <CardBody>
              {currentOrder?.buildShopSupplyProduct.map((item, index) => (
                <Row key={item._id} className="col-bottom">
                  <Col md="4">
                    <Col>
                      <Label className="label-tag-header">
                        {item.product_title}
                      </Label>
                      <div>
                        <Label className="label-tag"> SKU :</Label> {item.sku}
                      </div>
                      <div>
                        <Label className="label-tag"> Quantity :</Label>{' '}
                        {item.quantity}
                      </div>
                      <div>
                        <Label className="label-tag"> Price :</Label>{' '}
                        ${(Number(item?.price ?? 0) * Number(item?.quantity ?? 0)).toFixed(2)}
                      </div>
                    </Col>
                  </Col>
                  <Col md="4">
                    <Col>
                      <Label className="label-tag-header">
                        {"Description Notes"}
                      </Label>
                      <div>
                        <p className="label-tag" dangerouslySetInnerHTML={{ __html: item.description }} />
                      </div>
                    </Col>
                  </Col>
                </Row>
              ))}
            </CardBody>
          </Card>}

          {currentOrder?.serviceProduct.length > 0 && <Card>
            <CardHeader className="label-tag-header">Services</CardHeader>
            <CardBody>
              {currentOrder?.serviceProduct.map((item, index) => (
                <Row key={item._id} className="col-bottom">
                  <Col md="4">
                    <Col>
                      <Label className="label-tag-header">
                        {item.product_title}
                      </Label>
                      <div>
                        <Label className="label-tag"> SKU :</Label> {item.sku}
                      </div>
                      <div>
                        <Label className="label-tag"> Quantity :</Label>{' '}
                        {item.quantity}
                      </div>
                      <div>
                        <Label className="label-tag"> Price :</Label>{' '}
                        ${Number(item?.price ?? 0) * Number(item?.quantity ?? 0)}
                      </div>
                    </Col>
                  </Col>
                  <Col md="4">
                    <Col>
                      <Label className="label-tag-header">
                        {"Description Notes"}
                      </Label>
                      <div>
                        <p className="label-tag" dangerouslySetInnerHTML={{ __html: item.description }} />
                      </div>
                    </Col>
                  </Col>
                </Row>
              ))}
            </CardBody>
          </Card>}

          {(user && user?.isAdmin) ||
            (user?.isStaff &&
              user?.permission.includes(
                Constants.PERMISSION_NAME.ORDER_MANAGEMENT
              )) ||
            (user?.isStaff &&
              user?.permission.includes(
                Constants.PERMISSION_NAME.SHIPPING_MANAGEMENT
              )) ? (
            <>
              {(currentOrder?.shipping_box.length > 0) && <Card>
                <CardHeader>
                  <Label className="label-tag-header"> Shipping Details</Label>
                </CardHeader>

                <CardBody>
                  {currentOrder.shipping_box.map((item, index) =>
                    item.box.map((box) => (
                      <>
                        <div>
                          <Label className="label-tag">Box Name :</Label>{' '}
                          {box.name}
                        </div>
                        <div>
                          <Label className="label-tag">Dimentions :</Label>
                          Length({box.length})in * Width({box.width})in *
                          height({box.height})in
                        </div>
                        <div>
                          <Label className="label-tag">Box Weight :</Label>
                          Weight({box.weight})lb
                        </div>
                      </>
                    ))
                  )}
                  <div>
                    <Label className="label-tag">Shipping Mode :</Label>
                    {convertCarrierTypes(currentOrder.shipping_mode.carrier)} :{' '}
                    {convertServiceTypes(currentOrder.shipping_mode.service)}
                  </div>

                  <div>
                    <Label className="label-tag ">Shipping Charges :</Label>$
                    {Number(currentOrder?.shipping_mode?.totalCharges ?? 0).toFixed(2)}
                  </div>
                </CardBody>
              </Card>}
              {currentOrder?.comment && (<Card>
                <CardHeader>
                  <Label className="label-tag-header">
                    {' '}
                    Comment
                  </Label>
                </CardHeader>
                <CardBody className="couponcodelist">
                  <Row className="col-bottom">
                    <Col md="4">
                      <Col>
                        <Label className="label-tag">Comment: </Label>
                        {" "}{currentOrder?.comment}
                      </Col>
                    </Col>

                  </Row>
                </CardBody>
              </Card>)}
              {(currentOrder?.payment_detail !== undefined &&
                Object.keys(currentOrder?.payment_detail[0]).length > 0 && [6, 7].includes(currentOrder?.payment_mode)) && (
                  <Card>
                    <CardHeader>
                      <Label className="label-tag-header">
                        {' '}
                        Payment Detail
                      </Label>
                    </CardHeader>
                    <CardBody className="couponcodelist">
                      <Row className="col-bottom">
                        {Object.keys(currentOrder?.payment_detail[0].card_detail ?? {}).length > 0 ? (
                          <Col md="4">
                            <Col>
                              <Label className="label-tag-header">Credit Card</Label>
                              {/* <div>
                                <Label className="label-tag">
                                  {' '}
                                  Card Holder Name :
                                </Label>{' '}
                                {
                                  currentOrder?.payment_detail[0]?.credit_card
                                    ?.cardholderName
                                }
                              </div> */}
                              <div>
                                <Label className="label-tag">
                                  {' '}
                                  Card Type :
                                </Label>{' '}
                                {
                                  currentOrder?.payment_detail[0]?.card_detail?.brand
                                }
                              </div>
                              <div>
                                <Label className="label-tag">
                                  {' '}
                                  Expiration Date :
                                </Label>{' '}
                                {
                                  currentOrder?.payment_detail[0]?.card_detail
                                    ?.expiry
                                }
                              </div>
                              <div>
                                <Label className="label-tag">
                                  {' '}
                                  Card Number :
                                </Label>{' '}
                                {
                                  currentOrder?.payment_detail[0]?.card_detail
                                    ?.last_digits
                                }
                              </div>
                            </Col>
                          </Col>
                        ) : (
                          <Col md="4">
                            <Col>
                              <Label className="label-tag-header">PayPal</Label>
                              <div>
                                <Label className="label-tag">
                                  {' '}
                                  Payer Name :
                                </Label>{' '}
                                {`${currentOrder?.payment_detail[0]?.payer?.name?.given_name} ${currentOrder?.payment_detail[0]?.payer?.name?.surname}`}
                              </div>
                              <div>
                                <Label className="label-tag">
                                  {' '}
                                  Payer Email :
                                </Label>{' '}
                                {
                                  currentOrder?.payment_detail[0]?.payer
                                    ?.email_address
                                }
                              </div>
                              <div>
                                <Label className="label-tag">
                                  {' '}
                                  PaymentId :
                                </Label>{' '}
                                {
                                  currentOrder?.payment_detail[0]?.payer
                                    ?.payer_id
                                }
                              </div>
                              {/*<div>
                          <Label className="label-tag"> Card Number :</Label> {currentOrder?.payment_detail[0]?.credit_card?.maskedNumber}
                        </div> */}
                            </Col>
                          </Col>
                        )}
                        <Col md="4">
                          <Col>
                            <Label className="label-tag-header">Amount</Label>
                            <div>
                              <Label className="label-tag">
                                {' '}
                                Paid Amount :
                              </Label>{' '}
                              ${currentOrder?.paid_amount?.$numberDecimal}
                            </div>
                          </Col>
                        </Col>
                        {/* <Col md="4">
                          <Col>
                            <Label className="label-tag-header">
                              Shipping Address
                            </Label>
                            <div>
                              <Label className="label-tag"> Address :</Label>{' '}
                              {
                                currentOrder?.payment_detail[0]
                                  ?.shipping_address?.extendedAddress
                              }
                            </div>
                            <div>
                              <Label className="label-tag"> City :</Label>{' '}
                              {
                                currentOrder?.payment_detail[0]
                                  ?.shipping_address?.locality
                              }
                            </div>
                            <div>
                              <Label className="label-tag"> State :</Label>{' '}
                              {
                                currentOrder?.payment_detail[0]
                                  ?.shipping_address?.region
                              }
                            </div>
                            <div>
                              <Label className="label-tag"> Country :</Label>{' '}
                              {currentOrder?.payment_detail[0]?.shipping_address
                                ?.countryCodeAlpha3 === 'USA'
                                ? 'United States'
                                : currentOrder?.payment_detail[0]
                                  ?.shipping_address?.countryCodeAlpha3}
                            </div>
                            <div>
                              <Label className="label-tag"> Zipcode :</Label>{' '}
                              {
                                currentOrder?.payment_detail[0]
                                  ?.shipping_address?.postalCode
                              }
                            </div>
                          </Col>
                        </Col> */}
                      </Row>
                    </CardBody>
                  </Card>
                )}
              <Card>
                <CardHeader>
                  <Label className="label-tag-header"> Order Total</Label>
                </CardHeader>
                <Row>
                  <Col sm="6">

                    <CardBody className="couponcodelist">
                      <Label className="label-tag-header">{"Order Calculation Detail"}</Label>
                      <p className="data-ptag">
                        <strong className="label-tag couponcode">
                          Product(s) Total :
                        </strong>
                        ${Number(currentOrder.product_total).toFixed(2)}
                      </p>
                      {currentOrder.discounted_amount &&
                        currentOrder.discounted_amount['$numberDecimal'] > 0 ? (
                        (!currentOrder?.coupon?.coupon_code || (isNaN(currentOrder?.coupon?.coupon_code) || Number(currentOrder?.coupon?.coupon_code) <= productTotal(Number(currentOrder.product_total ?? 0), Number(currentOrder.fitting_total ?? 0)))) && <p className="data-ptag">
                          <strong className="label-tag couponcode">
                            Discount :
                          </strong>
                          -($
                          {Number(
                            currentOrder.discounted_amount['$numberDecimal']
                          ).toFixed(2)}
                          )
                          {(currentOrder.coupon && isNaN(currentOrder.coupon.coupon_code)) ? (
                            <>
                              {' '}
                              <a href="/admin/coupons">
                                <span className="coupon-display">
                                  ({currentOrder.coupon.coupon_code})
                                </span>
                              </a>
                            </>
                          ) : (
                            ''
                          )}
                        </p>
                      ) : null}

                      <p className="data-ptag">
                        <strong className="label-tag couponcode">
                          Build Parameter(s) total :
                        </strong>
                        ${Number(currentOrder.fitting_total).toFixed(2)}
                      </p>
                      {currentOrder.tax_applied && (
                        <>
                          <p className="data-ptag">
                            <strong className="label-tag couponcode">
                              Total Before Tax :
                            </strong>
                            $
                            {totalBeforeTax(currentOrder?.coupon?.coupon_code, Number(currentOrder.fitting_total ?? 0), Number(currentOrder.product_total ?? 0), Number(currentOrder.discounted_amount['$numberDecimal'] ?? 0))}

                          </p>
                          <p className="data-ptag">
                            <strong className="label-tag couponcode">
                              Sales Tax :
                            </strong>
                            ${Number(currentOrder.tax_total).toFixed(2)}
                          </p>
                        </>
                      )}
                      <p className="data-ptag">
                        <strong className="label-tag couponcode">
                          Shipping Charges :
                        </strong>
                        $
                        {Number(currentOrder?.shipping_mode?.totalCharges ?? 0).toFixed(2)}
                      </p>
                      {(currentOrder?.serviceProduct?.length > 0) &&
                        <p className="data-ptag">
                          <strong className="label-tag couponcode">
                            Service/Labour :
                          </strong>
                          ${Number(currentOrder?.serviceProduct.reduce((accumulator, currentValue) => accumulator + (currentValue.price * currentValue.quantity), 0)).toFixed(2)}
                        </p>}
                      {currentOrder.shipping_mode.shipping_discount &&
                        currentOrder.shipping_mode.shipping_discount > 0 ? (
                        <p className="data-ptag">
                          <strong className="label-tag couponcode">
                            Shipping Discount :
                          </strong>
                          -($
                          {Number(
                            currentOrder.shipping_mode.shipping_discount
                          ).toFixed(2)}
                          )
                          {(currentOrder.coupon && isNaN(currentOrder.coupon.coupon_code)) ? (
                            <>
                              {' '}
                              <a href="/admin/coupons">
                                <span className="coupon-display">
                                  ({currentOrder.coupon.coupon_code})
                                </span>
                              </a>
                            </>
                          ) : (
                            ''
                          )}
                        </p>
                      ) : null}
                      {currentOrder.shipping_mode.shipping_discount &&
                        currentOrder.shipping_mode.shipping_discount > 0 ? (
                        <p className="data-ptag">
                          <strong className="label-tag couponcode">
                            Net Shipping Amount :
                          </strong>
                          $
                          {(
                            Number(currentOrder.shipping_mode.totalCharges) -
                            Number(currentOrder.shipping_mode.shipping_discount)
                          ).toFixed(2)}
                        </p>
                      ) : null}
                      <p className="data-ptag">
                        {Number(currentOrder?.paid_amount?.$numberDecimal) > 0 && (
                          <>
                            <strong className="label-tag couponcode">
                              Paid Amount :
                            </strong>
                            $
                            {Number(
                              currentOrder?.paid_amount?.$numberDecimal
                            ).toFixed(2)}
                          </>
                        )}
                      </p>
                      {currentOrder.discounted_amount &&
                        currentOrder.discounted_amount['$numberDecimal'] > 0 ? (
                        (currentOrder.coupon && (!isNaN(currentOrder?.coupon?.coupon_code) && Number(currentOrder?.coupon?.coupon_code) > productTotal(Number(currentOrder.product_total ?? 0), Number(currentOrder.fitting_total ?? 0)))) && <p className="data-ptag">
                          <strong className="label-tag couponcode">
                            Discount :
                          </strong>
                          -($
                          {Number(
                            currentOrder.discounted_amount['$numberDecimal']
                          ).toFixed(2)}
                          )
                          {(currentOrder.coupon && isNaN(currentOrder.coupon.coupon_code)) ? (
                            <>
                              {' '}
                              <a href="/admin/coupons">
                                <span className="coupon-display">
                                  ({currentOrder.coupon.coupon_code})
                                </span>
                              </a>
                            </>
                          ) : (
                            ''
                          )}
                        </p>
                      ) : null}

                      <p className="data-ptag">
                        <strong className="label-tag couponcode">
                          Grand Total :
                        </strong>
                        $
                        {Number(currentOrder.grand_total?.$numberDecimal).toFixed(
                          2
                        )}
                      </p>


                      <p className="data-ptag">
                        {amountDue(currentOrder?.grand_total?.$numberDecimal, currentOrder?.paid_amount?.$numberDecimal, currentOrder.refunded_amount) >= 0 && (
                          <>
                            <strong className="label-tag couponcode">
                              Due Amount :
                            </strong>
                            $
                            {amountDue(currentOrder?.grand_total?.$numberDecimal, currentOrder?.paid_amount?.$numberDecimal, currentOrder.refunded_amount)}
                          </>
                        )}
                      </p>


                      <p className="data-ptag">
                        {currentOrder.refunded_amount ? (
                          <>
                            <strong className="label-tag couponcode">
                              Refund Amount :
                            </strong>
                            -(${Number(currentOrder.refunded_amount).toFixed(2)})
                          </>
                        ) : (
                          ''
                        )}
                      </p>
                      <p className="data-ptag">
                        {currentOrder.refunded_amount ? (
                          <>
                            <strong className="label-tag couponcode">
                              Final Total Amount :
                            </strong>
                            $
                            {
                              (currentOrder?.paid_amount?.$numberDecimal > currentOrder.refunded_amount) ? Number(currentOrder?.paid_amount?.$numberDecimal - currentOrder.refunded_amount).toFixed(2)
                                : Number(currentOrder.refunded_amount - currentOrder?.paid_amount?.$numberDecimal).toFixed(2)
                            }
                          </>
                        ) : (
                          ''
                        )}
                      </p>
                      <p className="data-ptag">
                        {currentOrder.refund_notes ? (
                          <>
                            <strong className="label-tag couponcode">
                              {VIEW_ORDER_LIST_ORDER_STATUS[currentOrder.order_status] === 'ON HOLD' ? 'Remarks :' :
                                "Refund Notes :"
                              }
                            </strong>
                            {currentOrder.refund_notes}
                          </>
                        ) : (
                          ''
                        )}
                      </p>
                    </CardBody>
                  </Col>
                  {currentOrder?.payment_mode && <Col sm="6">
                    <CardBody className="couponcodelist">
                      <Label className="label-tag-header">{"Order Payment Mode"}</Label>
                      <p className="data-ptag">
                        <strong className="label-tag couponcode">
                          Payment Mode :
                        </strong>
                        {Constants.ORDER_LIST_PAYMENT_METHOD[currentOrder?.payment_mode]}
                      </p>
                      {(currentOrder?.payment_comment) && <p className="data-ptag">
                        <strong className="label-tag couponcode">
                          Payment Comment :
                        </strong>
                        {currentOrder?.payment_comment}
                      </p>}
                    </CardBody>
                  </Col>}
                </Row>
              </Card>
              {currentOrder.delivery_instruction ? (
                <Card>
                  <CardHeader>
                    <Label className="label-tag-header">
                      {' '}
                      Delivery Instruction
                    </Label>
                  </CardHeader>
                  <CardBody>{currentOrder.delivery_instruction}</CardBody>
                </Card>
              ) : (
                ''
              )}
              {currentOrder.notify_emails?.length ? (
                <Card>
                  <CardHeader>
                    <Label className="label-tag-header">
                      {' '}
                      Additional emails for order notifications
                    </Label>
                  </CardHeader>
                  <CardBody>
                    {currentOrder.notify_emails.map((email, indx) => {
                      if (currentOrder.notify_emails.length - 1 === indx) {
                        return (
                          <p key={email} style={{ display: 'inline' }}>
                            {`${email}`}
                          </p>
                        );
                      }
                      return (
                        <p key={email} style={{ display: 'inline' }}>
                          {`${email}  |  `}
                        </p>
                      );
                    })}
                  </CardBody>
                </Card>
              ) : (
                ''
              )}
            </>
          ) : (
            ''
          )}
          <Card>
            <CardHeader>
              <Label className="label-tag-header"> Internal Notes </Label>
            </CardHeader>
            <CardBody>
              <InternalNotes
                user_id={user._id}
                order_id={currentOrder._id}
              ></InternalNotes>
            </CardBody>

            <CardFooter>
              {user &&
                user?.isStaff &&
                user?.permission.includes(
                  Constants.PERMISSION_NAME.BUILD_SHOP_MANAGEMENT
                ) && (
                  <>
                    <Button
                      type="button"
                      size="sm"
                      color="primary"
                      onClick={() => handleChageOrderStatus(currentOrder._id)}
                    >
                      Mark As Done
                    </Button>
                    &nbsp;
                  </>
                )}

              {/* {user &&
                user?.isStaff &&
                user?.permission.includes(
                  Constants.PERMISSION_NAME.SHIPPING_MANAGEMENT
                ) && (
                  <>
                    <Link
                      to={`/admin/orders/print-label/${match.params.order_id}`}
                      rel="noopener noreferrer"
                    >
                      <Button
                        type="button"
                        size="sm"
                        color="primary"
                        title="Print Ship Label"
                      >
                        Print Ship Label
                      </Button>
                    </Link>
                    &nbsp;
                  </>
                )} */}

              <Button
                onClick={() => navigate(-1)}
                type="reset"
                size="sm"
                color="danger"
              >
                <i className="fa fa-arrow-left"></i> Back
              </Button>
            </CardFooter>
          </Card>
        </Col>
      </Row>) :
        (<Row>
          <Col md="12">
            <Card>

              <CardBody style={{ textAlign: "center" }}>
                <Label>No record found.</Label>
              </CardBody>
            </Card>
          </Col>
        </Row>)
      }
    </div>
  );
};

ViewOrderDetails.propTypes = {
  getOrderById: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  currentOrder: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  currentOrder: state.order.currentOrder,
  loading: state.order.loading,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getOrderById,
  deleteBuildShopNotes,
  changeStatus,
  changeOrderStatus,
  getProductById,
})(ViewOrderDetails);
