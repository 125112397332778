/* eslint-disable */
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Alert,
} from 'reactstrap';
import PhoneInput, { getCountryCallingCode } from 'react-phone-number-input';
import extractNumber from 'utils/extractNumber';
import { getCountryShort } from 'utils/countryCode';
import 'react-phone-number-input/style.css';
import Autocomplete from 'react-google-autocomplete';
import { GOOGLE_MAPS_API_KEY } from '../../../config';
import { setGoogleAddressFormData } from 'actions/commonActions';
import PropTypes from 'prop-types';
import { Link, useLocation, useNavigate, useMatch } from 'react-router-dom';
import {
  printLabel,
  getShippingOrderById,
  calculateShippingRatesForLabelPrint,
} from 'actions/admin/order';
import { edit, getPackingSlipList } from 'actions/admin/packingSlip';
import { convertCarrierTypes, convertServiceTypes } from 'actions/commonActions';
import * as Constants from 'constants/index';
import Select from 'react-select';
import { useCKEditor, CKEditorEventAction } from 'ckeditor4-react';
import Errors from 'views/Notifications/Errors';
import Spinner from 'views/Spinner';
import { USER_TYPE } from 'constants/index';
import convert from 'convert-units';
import { CKEditor } from 'ckeditor4-react';
import ViewInternalNotes from 'views/Admin/Orders/ViewInternalNotes';
import BuildShopNotes from "views/Admin/Orders/BuildShopNotes";
import {
  SHIPPO_ADDRESS_FROM,
} from '../../../constants';
import AddressFormat from './Components/AddressFormat';
import useAddScript from 'customHooks/useAddScript';
const ShippingLabel = ({
  printLabel,
  getPackingSlipList,
  packingSlipList,
  packingSlipLoading,
  packingSlipSubLoading,
  errorList,
  getShippingOrderById,
  currentShippingOrder,
  shippingOrderLoading,
  calculateShippingRatesForLabelPrint,
  auth,
  shippingRatesLoading,
  shippingOrderRatesLoading,
  shippingLabelLoading,
  setGoogleAddressFormData
  // edit, //packingSlip
}) => {
  const navigate = useNavigate();
  const match = useMatch('/admin/orders/print-label/:order_id/:shipping_box_id');

  // //########################### usestate formdata print label ##############################
  const [formData, setFormData] = useState({
    description: '',
    shippingRate: {},
    isUpdatePackingSlip: false,
    packingSlipId: '',
  });
  /**************** expload from data *********************/
  const {
    description,
    isUpdatePackingSlip,
    packingSlipId
  } = formData;
  // //########################### usestate shipping mode error ##############################
  const [shippingModeError, setShippingModeError] = useState(
    'Something went wrong.'
  );

  //########################### usestate for shipping rates ##############################
  const [editDimensionToggle, setEditDimensionToggle] = useState(true);
  const [editDeliveryAddressToggle, setEditDeliveryAddressToggle] = useState(true);
  const [shippingDimenssionsEdited, setShippingDimenssionsEdited] = useState(false);
  const [deliveryAddressEdited, setDeliveryAddressEdited] = useState(false);

  const [editServiceTypeToggle, setEditServiceTypeToggle] = useState(true);

  const [shippingRates, setShippingRates] = useState([]);
  const [initialShippingRates, setInitialShippingRates] = useState([]);
  const [groupedRatesByProvider, setGroupedRatesByProvider] = useState({});
  const [shippingMessages, setShippingMessages] = useState([]);

  const [toAddress, setToAddress] = useState(null);
  const [currentShippoDetails, setCurrentShippoDetails] = useState(null);
  const [firstLoad, setFirstLoad] = useState(false);
  const [customItems, setCustomItems] = useState([]);
  const [shippoPackages, setShippoPackages] = useState([]);
  const [shippingDimensionLabel, setShippingDimensionLabel] = useState({});
  const [shippingMode, setShippingMode] = useState({});
  const [deliveryInstruction, setDeliveryInstruction] = useState('');
  //########################## set user type ##############################
  // 1 for Admin/Staff-OrderManger and 5 for other staff
  const [userType, setUserType] = useState(1);
  //################# set select packing slip name value ################
  const [packingSlipValue, setPackingSlipValue] = useState('');
  const [packingSlipSelectList, setPackingSlipSelectList] = useState([]);
  //################# set metric or imperial system #####################
  const [isMetricImperial, setIsMetricImperial] = useState(false);
  const [shippoWeightUnit, setShippoWeightUnit] = useState('lb');
  const [shippoLengthUnit, setShippoLengthUnit] = useState('in');
  const [shippingModeChanged, setShippingModeChanged] = useState(false);
  const [defaultCountry, setDefaultCountry] = useState('US');
  const [defaultCountryCode, setDefaultCountryCode] = useState('+1');
  const [phoneError, setPhoneError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [addressError, setAddressError] = useState('');
  const [editDimenssionsCanceled, setEditDimenssionsCanceled] = useState(false);
  //################## Initillise sorting searching parameters by default values ###################
  const [sortingParams, setSortingParams] = useState({
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: 'created_at',
    ascending: 'desc',
    query: '',
  });

  const [autocompleteValue, setAutocompleteValue] = useState('');
  const initialNewAddressData = {
    name: '',
    email: '',
    contact_no: '',
    iute164_phone: '',
    address: '',
    address_line_1: '',
    address_line_2: '',
    latitute: 0,
    longitude: 0,
    city: '',
    zipcode: '',
    country: '',
    state: '',
    google_id: '',
    google_place_id: '',
    country_code: '',
  };

  const [newAddressData, setNewAddressData] = useState(initialNewAddressData);

  useEffect(() => {
    const fetchDefaultCountryCode = async () => {
      try {
        const response = await fetch('https://geolocation-db.com/json/');
        const data = await response.json();
        const countryCode = data.country_code || '';

        // Use libphonenumber-js to format the country code
        const phoneNumber = getCountryCallingCode(countryCode);


        if (phoneNumber) {
          setDefaultCountry(countryCode);
          setNewAddressData({ ...formData, ccode: `+${phoneNumber}` });
          setDefaultCountryCode(`+${phoneNumber}`);

        }
      } catch (error) {
        console.error('Error fetching default country code:', error);
      }
    };

    fetchDefaultCountryCode();
  }, []);

  //########################### usestate for EasyPost Order Id ##############################
  const [easyPostOrderId, setEasyPostOrderId] = useState('');

  const [canEditorder, setCanEditOrder] = useState(true);

  // set user type(Role)
  useMemo(() => {
    if (auth.isAuthenticated && !auth.loading) {
      if (auth?.user?.isAdmin || (auth?.user?.isStaff && auth?.user?.permission.includes(Constants.PERMISSION_NAME.ORDER_MANAGEMENT))) {
        setUserType(1);
      } else {
        setUserType(auth.user.userType);
      }
    }
  }, [auth]);

  //################## Get Details of order by id ###################
  useMemo(() => {
    getShippingOrderById(match.params.order_id, match.params.shipping_box_id);
  }, [getShippingOrderById, match.params.order_id, match.params.shipping_box_id]);

  //console.log(currentShippingOrder,'currentShippingOrder data ');return false;
  //########## set calculate shipping label needed data ##########
  useEffect(() => {
    setDeliveryInstruction(currentShippingOrder?.delivery_instruction);
    setToAddress({
      country_code: currentShippingOrder?.shipping_address?.country_code,
      zipcode: currentShippingOrder?.shipping_address?.zipcode,
      city: currentShippingOrder?.shipping_address?.city,
      state: currentShippingOrder?.shipping_address?.state,
      ccode: currentShippingOrder?.shipping_address?.ccode,
      contact_no: currentShippingOrder?.shipping_address?.contact_no,
      address: currentShippingOrder?.shipping_address?.address,
      addressData: currentShippingOrder?.shipping_address,
      name: currentShippingOrder?.shipping_address?.name,
      email: currentShippingOrder?.shipping_address?.email,
      country: currentShippingOrder?.shipping_country
    })

    setShippingMode(currentShippingOrder?.shipping_mode);

    if (currentShippingOrder?.label_url) {
      setCanEditOrder(true)
    } else {
      setCanEditOrder(false)
    }
  }, [currentShippingOrder]);

  //################## to get updated list ###################
  useEffect(() => {
    if (!firstLoad && toAddress != null) {
      setShippingRates([]);
      setInitialShippingRates([]);
      setEasyPostOrderId('');
      setGroupedRatesByProvider({});
      setShippingMessages([]);
      setShippingModeError('');
      setShippingModeChanged(false);
      setFormData((ev) => ({
        ...ev,
        shippingRate: {},
      }));
      if (toAddress != null && currentShippoDetails.shippoPackages.length > 0 && currentShippoDetails.customItems.length > 0) {
        calculateShippingRatesForLabelPrint(
          match.params.order_id,
          match.params.shipping_box_id,
          toAddress,
          currentShippoDetails,
        ).then((res) => {
          if (res.status) {
            setFirstLoad(true);
            setEasyPostOrderId(res.order_id)
            setShippingRates(res.rate);
            setInitialShippingRates(res.rate);
            if (res.rate.length)
              setGroupedRatesByProvider(groupRatesByProvider(res.rate, 'carrier'));
            res.rate.map((value, key) => {
              if (value?.carrier == shippingMode?.carrier && value?.service == shippingMode?.service) {
                let shippingRate =
                {
                  rate: value?.rate_id,
                  charges: value?.totalCharges,
                  ...value
                }

                setFormData((ev) => ({
                  ...ev,
                  shippingRate: shippingRate,
                  easy_post_order_id: res.order_id
                }));
                setShippingMode(value);
                if (value.totalCharges != currentShippoDetails.shipping_mode?.totalCharges) {
                  setShippingModeChanged(true)
                }

              }
            })
            if (res.message) {
              setShippingMessages(res.message);
            }
          } else {
            if (res.message.__all__) {
              setShippingModeError(res.message.__all__[0]);
            } else if (res.message.parcels) {
              let error = Object.keys(res.message.parcels[0]).length > 0 ? < p >
                <span>{res.message.parcels[0].__all__ ? res.message.parcels[0].__all__[0] : null}</span><br></br>
                <span>{res.message.parcels[0].weight ? res.message.parcels[0].weight[0] : null}</span><br></br>
                <span>{res.message.parcels[0].height ? res.message.parcels[0].height[0] : null}</span><br></br>
                <span>{res.message.parcels[0].length ? res.message.parcels[0].length[0] : null}</span><br></br>
                <span>{res.message.parcels[0].width ? res.message.parcels[0].width[0] : null}</span><br></br>
              </p> : null

              setShippingModeError(error ?? 'Parcel Error');
            } else {
              setShippingModeError(res.message);
            }
            setShippingModeChanged(false)
            setEasyPostOrderId('');
            setShippingRates([]);
            setGroupedRatesByProvider({});
            setFormData((ev) => ({
              ...ev,
              shippingRate: {},
            }));
          }
        });
      }
    }
    console.log(currentShippoDetails, "*********************")
  }, [toAddress, currentShippoDetails]);

  useEffect(() => {
    setShippingRates([]);
    setEasyPostOrderId('');
    setGroupedRatesByProvider({});
    setShippingMessages([]);
    setShippingModeError('');
    setShippingModeChanged(false);
    setFormData((ev) => ({
      ...ev,
      shippingRate: {},
    }));
    if (toAddress != null && currentShippoDetails.shippoPackages.length > 0 && currentShippoDetails.customItems.length > 0) {
      calculateShippingRatesForLabelPrint(
        match.params.order_id,
        match.params.shipping_box_id,
        toAddress,
        currentShippoDetails,
      ).then((res) => {
        if (res.status) {
          setFirstLoad(true);
          setEasyPostOrderId(res.order_id)
          setShippingRates(res.rate);
          if (res.rate.length)
            setGroupedRatesByProvider(groupRatesByProvider(res.rate, 'carrier'));
          res.rate.map((value, key) => {
            if (value?.carrier == shippingMode?.carrier && value?.service == shippingMode?.service) {
              let shippingRate =
              {
                rate: value?.rate_id,
                charges: value?.totalCharges,
                ...value
              }

              setFormData((ev) => ({
                ...ev,
                shippingRate: shippingRate,
                easy_post_order_id: res.order_id
              }));
              setShippingMode(value);
              if (value.totalCharges != currentShippoDetails.shipping_mode?.totalCharges) {
                setShippingModeChanged(true)
              }

            }
          })
          if (res.message) {
            setShippingMessages(res.message);
          }
        } else {
          if (res.message.__all__) {
            setShippingModeError(res.message.__all__[0]);
          } else if (res.message.parcels) {
            let error = Object.keys(res.message.parcels[0]).length > 0 ? < p >
              <span>{res.message.parcels[0].__all__ ? res.message.parcels[0].__all__[0] : null}</span><br></br>
              <span>{res.message.parcels[0].weight ? res.message.parcels[0].weight[0] : null}</span><br></br>
              <span>{res.message.parcels[0].height ? res.message.parcels[0].height[0] : null}</span><br></br>
              <span>{res.message.parcels[0].length ? res.message.parcels[0].length[0] : null}</span><br></br>
              <span>{res.message.parcels[0].width ? res.message.parcels[0].width[0] : null}</span><br></br>
            </p> : null

            setShippingModeError(error ?? 'Parcel Error');
          } else {
            setShippingModeError(res.message);
          }
          setShippingModeChanged(false)
          setEasyPostOrderId('');
          setShippingRates([]);
          setGroupedRatesByProvider({});
          setFormData((ev) => ({
            ...ev,
            shippingRate: {},
          }));
        }
      });
    }
  }, [toAddress]);

  useEffect(() => {
    if (editDimenssionsCanceled && editDimensionToggle && !shippingDimenssionsEdited) {
      console.log("Entereddddd")
      setShippingRates([]);
      setEasyPostOrderId('');
      setGroupedRatesByProvider({});
      setShippingMessages([]);
      setShippingModeError('');
      setShippingModeChanged(false);
      setFormData((ev) => ({
        ...ev,
        shippingRate: {},
      }));
      if (toAddress != null && currentShippoDetails.shippoPackages.length > 0 && currentShippoDetails.customItems.length > 0) {
        calculateShippingRatesForLabelPrint(
          match.params.order_id,
          match.params.shipping_box_id,
          toAddress,
          currentShippoDetails,
        ).then((res) => {
          if (res.status) {
            setFirstLoad(true);
            setEasyPostOrderId(res.order_id)
            setShippingRates(res.rate);
            if (res.rate.length)
              setGroupedRatesByProvider(groupRatesByProvider(res.rate, 'carrier'));
            res.rate.map((value, key) => {
              if (value?.carrier == shippingMode?.carrier && value?.service == shippingMode?.service) {
                let shippingRate =
                {
                  rate: value?.rate_id,
                  charges: value?.totalCharges,
                  ...value
                }

                setFormData((ev) => ({
                  ...ev,
                  shippingRate: shippingRate,
                  easy_post_order_id: res.order_id
                }));
                setShippingMode(value);
                if (value.totalCharges != currentShippoDetails.shipping_mode?.totalCharges) {
                  setShippingModeChanged(true)
                }

              }
            })
            if (res.message) {
              setShippingMessages(res.message);
            }
          } else {
            if (res.message.__all__) {
              setShippingModeError(res.message.__all__[0]);
            } else if (res.message.parcels) {
              let error = Object.keys(res.message.parcels[0]).length > 0 ? < p >
                <span>{res.message.parcels[0].__all__ ? res.message.parcels[0].__all__[0] : null}</span><br></br>
                <span>{res.message.parcels[0].weight ? res.message.parcels[0].weight[0] : null}</span><br></br>
                <span>{res.message.parcels[0].height ? res.message.parcels[0].height[0] : null}</span><br></br>
                <span>{res.message.parcels[0].length ? res.message.parcels[0].length[0] : null}</span><br></br>
                <span>{res.message.parcels[0].width ? res.message.parcels[0].width[0] : null}</span><br></br>
              </p> : null

              setShippingModeError(error ?? 'Parcel Error');
            } else {
              setShippingModeError(res.message);
            }
            setShippingModeChanged(false)
            setEasyPostOrderId('');
            setShippingRates([]);
            setGroupedRatesByProvider({});
            setFormData((ev) => ({
              ...ev,
              shippingRate: {},
            }));
          }
        });
      }
    }
  }, [currentShippoDetails]);

  //################## to get packing slip list ###################
  useEffect(() => {
    getPackingSlipList(sortingParams);
  }, [sortingParams]);

  //########## set select packing slip list optiuons ##########
  useEffect(() => {
    setPackingSlipSelectList(
      packingSlipList?.length > 0
        ? packingSlipList.map((item, index) => {
          return {
            label: item.name,
            value: item._id
          }
        })
        : [])
  }, [packingSlipList]);

  //########## set selected packing slip  on packing slip option selection change ##########
  useEffect(() => {
    setPackingSlipValue(
      packingSlipSelectList?.length > 0
        ? packingSlipSelectList.filter((item, index) => {
          if (item.value == packingSlipId) return [item[index]];
        })
        : ''
    );
  }, [packingSlipSelectList]);

  //############################ reset dimensions ################################################
  const [totalProduct, setTotalProduct] = useState(0);
  useEffect(() => {
    if (!shippingDimenssionsEdited) {

      console.log("Triggered")
      setShippoPackages([]);
      setCustomItems([]);
      setShippingDimensionLabel({});
      currentShippingOrder?.shipping_box?.map((productItems) => {
        let shippoPackage = {
          length: productItems.box[0].length,
          width: productItems.box[0].width,
          height: productItems.box[0].height,
          distance_unit: shippoLengthUnit,
          weight: productItems.weight,
          mass_unit: shippoWeightUnit,
        };
        let packageObject = {};
        packageObject[productItems._id] = shippoPackage;
        // shippingDimensionLabel[productItems._id] = 'Box- '
        //   + productItems.box[0].name + ' '
        //   + productItems.items.product_name.map((value, key) => {
        //     return 'Product- ' + value + ' Quantity- ' + productItems.items.item_count[key]
        //   });

        setShippoPackages(oldShippoPackages => [...oldShippoPackages, packageObject]);
        // setShippingDimensionLabel({ ...shippingDimensionLabel });
      });

      let total = 0;

      currentShippingOrder?.products?.map((productItems) => {
        total = total + productItems.quantity;
        let customItem = {
          description: productItems.product_title,
          net_weight: productItems.product_weight * productItems.quantity,
          mass_unit: 'g',
          value_amount: productItems.product_total_price['$numberDecimal'] * productItems.quantity,
          value_currency: 'USD',
          origin_country: 'US',
          quantity: productItems.quantity,
        };
        setCustomItems(oldCustomItems => [...oldCustomItems, customItem]);
        setTotalProduct(total);
      });
      setEditDimenssionsCanceled(true);
    }
  }, [currentShippingOrder, editDimensionToggle]);

  //########## set details for shipping rates api ##########
  useEffect(() => {
    setCurrentShippoDetails({
      'customItems': customItems,
      'shippoPackages': shippoPackages,
    });
    let desc = [{ description: '' }];
    let descData = '';
    if (packingSlipList?.length > 0)
      desc = packingSlipList.filter((item, index) => {
        return item._id == '62ff61cc86d852b19e9fb89c'//e.value;
      })
    //     descData += `<html><head><link href="https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@100;200;300;400;500;600;700;800;900&display=swap" rel="stylesheet"><style>body{font-family: 'Montserrat Alternates', sans-serif;}</style></head><body><table  cellpadding="0" cellspacing="0" style="font-size: 13px; width: 600px; box-sizing: border-box; margin: 0 auto; font-family: 'Montserrat Alternates', sans-serif !important;">
    // 	<tbody>
    // 		<tr>
    // 			<td style="width: 600px;">
    // 				<table  cellpadding="0" cellspacing="0" style="width: 600px;">
    // 					<tr>
    // 						<td>
    // 							<table style="width:600" cellpadding="0" cellspacing="0">
    // 								<tr>
    // 									<td style="text-align: left; font-weight: 600; background: #6d2a5f; font-size: 13px; padding: 5px 10px; box-sizing: border-box; color: #fff; width:500px">PACKING SLIP (ORDER ID : ${currentShippingOrder.order_id})</td>
    // 									<td style="text-align: right; font-weight: 600; background: #6d2a5f; font-size: 13px; padding: 5px 10px; box-sizing: border-box; color: #fff; width: 120px; text-transform: uppercase;white-space: nowrap;">Quantity : ${totalProduct}</td>
    // 								</tr>
    // 							</table>
    // 						</td>
    // 					</tr>`;
    //     currentShippingOrder?.products?.map((productItems) => {
    //       let variationHtml = '';
    //       Object.keys(productItems.product_variation).map(variationKey => {
    //         variationHtml += `<p style="margin:3px 0px;"><strong>${variationKey} : </strong>${productItems.product_variation[variationKey]}</p>`;
    //       })
    //       if (productItems?.fitting_options.length > 0) {
    //         descData += `<tr style="page-break-inside: avoid;">
    // 						<td colspan="2" style="page-break-inside: avoid; border-bottom: 1px solid #ccc;">
    // 							<table style="page-break-inside: avoid; width:600px;" cellspacing="0" cellpadding="0">
    // 								<tr>
    // 									<td style="width:600px;" colspan="2">
    // 										<table style="border-collapse: collapse; width:500px;">
    // 											<tr>
    // 												<td>
    // 													<table style="width:600;">
    // 														<tr>
    // 															<td style="width: 100px; max-width: 100px; vertical-align: top; text-align: left;font-size: 13px; padding: 5px 0px; box-sizing: border-box;"><strong>Item:- </strong></td>
    // 															<td style="width: 300px;max-width: 300px; vertical-align: top; font-size: 13px; text-align: left; padding: 5px 5px 5px 0px; box-sizing: border-box;">
    // 																<strong style="display:block;">${productItems.product_title}</strong>
    // 																<p style="margin:3px 0px;font-weight: bold;"><strong>SKU : </strong>${productItems.sku}</p>
    // 																${variationHtml}
    // 																<p style="margin:3px 0px 0px; font-size: 13px; padding-top:10px"><strong>Build Parameters :- </strong></p>
    //                                 ${productItems.fitting_options.filter((item) => ["Adaptors"].includes(item.attribute_name)).map((fittingItem) => (

    //           `<p style="margin:3px 0px; font-size: 13px;"><strong>Adaptor :</strong> ${fittingItem.option_name}</p>
    //           <p style="margin:3px 0px; font-size: 13px;"><strong>Adaptor Setting :</strong> ${fittingItem.sub_option_name}</p>
    //           `


    //         ))
    //           }     
    //            ${productItems.fitting_options.filter((item) => ["Grips"].includes(item.attribute_name)).map((fittingItem) => (

    //             ` <p style="margin:0px 0px; font-size: 13px;"><strong>Grip : </strong>${fittingItem.option_name}</p>
    //             <p style="margin:3px 0px; font-size: 13px;"><strong>Grip Model :</strong> ${fittingItem.sub_option_name}</p>
    //             `

    //           ))
    //           }  
    //                                     </td >
    // 														</tr >
    //   <tr>
    //     <td style="width: 100px; max-width: 100px; vertical-align: top; text-align: left;font-size: 13px; padding: 5px 0px; box-sizing: border-box;"><strong>Quantity:- </strong></td>
    //     <td style="width: 300px;max-width: 300px; vertical-align: top; font-size: 13px; text-align: left; padding: 5px 5px 5px 0px; box-sizing: border-box;">${productItems.quantity}
    //     </td>
    //   </tr>
    // 													</table >
    // 												</td >

    // 											</tr >
    //                       ${productItems.fitting_options.filter((item) => ["Product Customization"].includes(item.attribute_name)).map((fittings) => (
    //             (fittings.attribute_name == 'Product Customization') ?
    //               ` <tr>
    // 												<td style="font-size:13px;padding:20px 0px 0px 0px;">
    // 													<strong>Customization Notes:-</strong>
    // 													<p style="margin:0px; font-size:13px; line-height: 22px;padding: 3px 0px 5px 0px;">${fittings.attribute_value}</p>
    // 												</td>
    // 											</tr>`
    //               :
    //               ``
    //           ))
    //           } 


    // 											<tr>
    // 												<td style=" font-size:13px;padding:20px 0px 0px 0px;">
    // 													<strong>Build Shop Notes:-</strong>
    // 													<p style="margin:0px; font-size:13px; line-height: 22px;padding: 3px 0px 5px 0px;">${productItems.build_shop_notes ? productItems.build_shop_notes : 'N/A'}</p>
    // 												</td>
    // 											</tr>
    // 										</table >
    // 									</td >
    // 								</tr >
    // 							</table >
    // 						</td >
    // 					</tr > `;
    //       } else {
    //         descData += `<tr>
    //   <td colspan="2" style="page-break-inside: avoid; border-bottom: 1px solid #ccc;">
    //     <table style="page-break-inside: avoid; width:600px;" cellspacing="0" cellpadding="0">
    //       <tr>
    //         <td style="width:600px; " colspan="2">
    //           <table style="border-collapse: collapse; width:500px;">
    //             <tr>
    //               <td colspan="2">
    //                 <table cellpadding="0" cellspacing="0" style="width:600;">
    //                   <tr>
    //                     <td style="width: 100px; max-width: 100px; vertical-align: top; text-align: left;font-size: 13px; padding: 5px 0px; box-sizing: border-box;"><strong>Item:- </strong></td>
    //                     <td style="width: 300px;max-width: 300px; vertical-align: top; font-size: 13px; text-align: left; padding: 5px 5px 5px 0px; box-sizing: border-box;">
    //                       <strong style="display:block;">${productItems.product_title}</strong>
    //                       <p style="margin:3px 0px; font-weight: bold;"><strong>SKU : </strong>${productItems.sku}</p>
    //                       ${variationHtml}
    //                     </td>
    //                   </tr>
    //                   <tr>
    //                     <td style="width: 100px; max-width: 100px; vertical-align: top; text-align: left;font-size: 13px; padding: 5px 0px; box-sizing: border-box;"><strong>Quantity:- </strong></td>
    //                     <td style="width: 300px; max-width: 300px; vertical-align: top; font-size: 13px; text-align: left; padding: 5px 5px 5px 0px; box-sizing: border-box;">${productItems.quantity}
    //                     </td>
    //                   </tr>
    //                 </table>
    //               </td>

    //             </tr>
    //           </table>
    //         </td>

    //       </tr>

    //     </table>
    //   </td>
    // 					</tr > `;
    //       }

    //     });
    //     descData += `					
    // 				</table >
    // 			</td >
    // 		</tr >
    // 	</tbody >
    // </table ></body></html> `;

    setFormData((ev) => ({
      ...ev,
      description: currentShippingOrder,
      totalProduct: totalProduct,
    }));
  }, [customItems, shippoPackages, totalProduct]);

  const [editor, setEditor] = useState(null);

  useEffect(() => {
    if (editor) {
      editor.setData(description);
    }
  }, [packingSlipId]);

  const CkEditor = useMemo(() => {

    const onBeforeLoad = (e) => {
      setEditor(e.editor);
    }

    const onBlur = (e) => {
      const range = e.editor.getSelection().getRanges()[0]; // store the selection
      e.editor.focus();
      range.select(); // restore previous selection
    }
    return (
      <div >
        <CKEditor
          initData={description}
          id="description"
          name="description"
          config={Constants.CKEditorConfig}
          onChange={(event) => handelDescriptionChange(event)}
          onLoaded={(e) => onBeforeLoad(e)}
          invalid={errorList.description ? true : false}
        />
        {/* <CKEditor
          initData={value}
          onLoaded={onBeforeLoad}
        /> */}
      </div>
    );
  }, [description])


  //################################ change event for packing slip body(description) ###################################
  const handelDescriptionChange = (event) => {
    setFormData((ev) => ({
      ...ev,
      description: event.editor.getData(),
    }));
  };

  //################################ change event for delivery instruction ###################################
  const onChangeDeliveryInstruction = (e) => {
    setDeliveryInstruction(e.target.value)
  }

  const onSubmitShippingDimenssions = (e) => {
    setShippingRates([]);
    setEasyPostOrderId('');
    setGroupedRatesByProvider({});
    setShippingMessages([]);
    setShippingModeError('');
    setShippingModeChanged(false);
    setFormData((ev) => ({
      ...ev,
      shippingRate: {},
    }));
    if (toAddress != null && currentShippoDetails.shippoPackages.length > 0 && currentShippoDetails.customItems.length > 0)
      calculateShippingRatesForLabelPrint(
        match.params.order_id,
        match.params.shipping_box_id,
        toAddress,
        currentShippoDetails,
      ).then((res) => {
        if (res.status) {
          setEasyPostOrderId(res.order_id)
          setShippingRates(res.rate);
          if (res.rate.length)
            setGroupedRatesByProvider(groupRatesByProvider(res.rate, 'carrier'));
          res.rate.map((value, key) => {
            if (value?.carrier == shippingMode?.carrier && value?.service == shippingMode?.service) {
              let shippingRate =
              {
                rate: value?.rate_id,
                charges: value?.totalCharges,
                ...value
              }

              setFormData((ev) => ({
                ...ev,
                shippingRate: shippingRate,
                easy_post_order_id: res.order_id
              }));
              setShippingMode(value);
              if (value.totalCharges != currentShippoDetails.shipping_mode?.totalCharges) {
                setShippingModeChanged(true)
              }

            }
          })
          setShippingDimenssionsEdited(true);
          setEditDimenssionsCanceled(false);
          setEditDimensionToggle(!editDimensionToggle);
          if (res.message) {
            setShippingMessages(res.message);
          }
        } else {
          if (res.message.__all__) {
            setShippingModeError(res.message.__all__[0]);
          } else if (res.message.parcels) {
            let error = Object.keys(res.message.parcels[0]).length > 0 ? < p >
              <span>{res.message.parcels[0].__all__ ? res.message.parcels[0].__all__[0] : null}</span><br></br>
              <span>{res.message.parcels[0].weight ? res.message.parcels[0].weight[0] : null}</span><br></br>
              <span>{res.message.parcels[0].height ? res.message.parcels[0].height[0] : null}</span><br></br>
              <span>{res.message.parcels[0].length ? res.message.parcels[0].length[0] : null}</span><br></br>
              <span>{res.message.parcels[0].width ? res.message.parcels[0].width[0] : null}</span><br></br>
            </p> : null

            setShippingModeError(error ?? 'Parcel Error');
          } else {
            setShippingModeError(res.message);
          }
          setShippingModeChanged(false)
          setEasyPostOrderId('');
          setShippingRates([]);
          setGroupedRatesByProvider({});
          setFormData((ev) => ({
            ...ev,
            shippingRate: {},
          }));
        }
      });
  }

  const onChangeNewAddress = (e) => {
    if (e.target.name === 'name') {
      setNewAddressData({ ...newAddressData, name: e.target.value.replace(/[&#<>@]/g, '') })
    } else {
      const newFormData = { ...newAddressData, [e.target.name]: e.target.value.trimLeft() };
      newFormData['address'] = `${newFormData.address_line_1} ${newFormData.address_line_2 ? newFormData.address_line_2 + ',' : ''
        } ${newFormData.city} ${newFormData.state} ${newFormData.zipcode} ${newFormData.country
        }`;
      setNewAddressData(newFormData);
    }
  };

  const onPhoneChange = (number) => {
    const contact_no = extractNumber(number, defaultCountryCode);
    setNewAddressData((form) => ({
      ...form,
      contact_no: contact_no,
      iute164_phone: number ?? '',
    }));
  };

  const onCountryChange = (code) => {
    setNewAddressData((form) => ({
      ...form,
      ccode: '+' + getCountryCallingCode(code ?? getCountryShort(defaultCountryCode)),
    }));
    setDefaultCountryCode('+' + getCountryCallingCode(code ?? getCountryShort(defaultCountryCode)));
  };

  const setGMapPlace = (location) => {

    const google_id = location.id;
    const google_place_id = location.place_id;
    const address = location.formatted_address;
    const addresses = location.address_components;
    let latitute = location?.geometry?.location?.lat((e) => e.Scopes[0].e);
    let longitude = location?.geometry?.location?.lng((e) => e.Scopes[0].f);

    let city = '';
    let state = '';
    let address_line_1 = '';
    let address_line_2 = '';
    let country = '';
    let country_code = '';
    let zipcode = '';
    if (addresses)
      addresses.forEach((e) => {
        switch (e.types[0]) {
          case 'street_number':
          case 'route':
            address_line_1 += (address_line_1 ? ' ' : '') + e.long_name;

            break;
          case 'sublocality_level_2':
          case 'sublocality_level_1':
            address_line_2 += (address_line_2 ? ' ' : '') + e.long_name;
            break;
          case 'locality':
          case 'sublocality_level_1':
          case 'administrative_area_level_3':
          case 'administrative_area_level_2':
          case 'postal_town':
            if (city == '') {
              city = e.long_name;
            }
            break;
          case 'administrative_area_level_1':
            state = e.long_name;
            break;
          case 'postal_code':
          case 'postal_code_prefix':
            zipcode = e.long_name;
            break;
          case 'country':
            country = e.long_name;
            country_code = e.short_name;
            break;
          default:
            break;
        }
      });
    setNewAddressData((prevState) => {
      return {
        ...prevState,
        address,
        address_line_1,
        address_line_2,
        latitute,
        longitude,
        city,
        google_id,
        google_place_id,
        state,
        country,
        country_code,
        zipcode,
      }
    });
    setAutocompleteValue('');
  };
  const handleInputChange = (event) => {
    setAutocompleteValue(event.target.value);
  };

  const onClickCancleDimenssions = (e) => {
    e.preventDefault();
    console.log("OUT CAll Back")
    setEditDimensionToggle(!editDimensionToggle);
    if (shippingDimenssionsEdited) {
      console.log("In Call Back")
      setShippingRates(initialShippingRates);
      setGroupedRatesByProvider(groupRatesByProvider(initialShippingRates, 'carrier'));
      initialShippingRates.map((value, key) => {
        if (value?.carrier == shippingMode?.carrier && value?.service == shippingMode?.service) {
          let shippingRate =
          {
            rate: value?.rate_id,
            charges: value?.totalCharges,
            ...value
          }

          setFormData((ev) => ({
            ...ev,
            shippingRate: shippingRate,
            easy_post_order_id: match.params.order_id,
          }));
          setShippingMode(value);
          if (value.totalCharges != currentShippoDetails.shipping_mode?.totalCharges) {
            setShippingModeChanged(true)
          }

        }
      })
      setShippingDimenssionsEdited(false);
    }
  };

  //################################ change event for shipping box dimension change ###########################
  const onShipBoxDimensionChange = (e, packedShippingBoxId) => {
    let updatedShippoPackages = [];
    switch (e.target.name) {
      case 'package_weight':
        updatedShippoPackages = shippoPackages?.map((pack) => {
          if (Object.keys(pack)[0] === packedShippingBoxId) {
            Object.values(pack)[0].weight = e.target.value;
          }
          return pack;
        });
        setShippoPackages(updatedShippoPackages);
        break;
      case 'package_width':
        updatedShippoPackages = shippoPackages?.map((pack) => {
          if (Object.keys(pack)[0] === packedShippingBoxId) {
            Object.values(pack)[0].width = e.target.value;
          }
          return pack;
        });
        setShippoPackages(updatedShippoPackages);
        break;
      case 'package_height':
        updatedShippoPackages = shippoPackages?.map((pack) => {
          if (Object.keys(pack)[0] === packedShippingBoxId) {
            Object.values(pack)[0].height = e.target.value;
          }
          return pack;
        });
        setShippoPackages(updatedShippoPackages);
        break;
      case 'package_length':
        updatedShippoPackages = shippoPackages?.map((pack) => {
          if (Object.keys(pack)[0] === packedShippingBoxId) {
            Object.values(pack)[0].length = e.target.value;
          }
          return pack;
        });
        setShippoPackages(updatedShippoPackages);
        break;
      default:
        break;
    }
  };

  //##################### update actual packing slip #########################
  const handleCheckedChange = (e) => {
    setFormData((ev) => ({
      ...ev,
      isUpdatePackingSlip: e.target.checked,
    }));
  };

  const handleUnitsCheckedChange = (e) => {
    setIsMetricImperial(() => (e.target.checked));
    let updatedShippoPackages = [];
    if (e.target.checked) {
      setShippoWeightUnit('g');
      setShippoLengthUnit('cm');
      updatedShippoPackages = shippoPackages?.map((pack) => {
        Object.values(pack)[0].weight = (convert(Object.values(pack)[0].weight).from('lb').to('g').toFixed(4));
        Object.values(pack)[0].length = (convert(Object.values(pack)[0].length).from('in').to('cm').toFixed(4));
        Object.values(pack)[0].width = (convert(Object.values(pack)[0].width).from('in').to('cm').toFixed(4));
        Object.values(pack)[0].height = (convert(Object.values(pack)[0].height).from('in').to('cm').toFixed(4));
        Object.values(pack)[0].distance_unit = 'cm';
        Object.values(pack)[0].mass_unit = 'g';
        return pack;
      });
      setShippoPackages(updatedShippoPackages);
    } else {
      setShippoWeightUnit('lb');
      setShippoLengthUnit('in');
      updatedShippoPackages = shippoPackages?.map((pack) => {
        Object.values(pack)[0].weight = (convert(Object.values(pack)[0].weight).from('g').to('lb').toFixed(4));
        Object.values(pack)[0].length = (convert(Object.values(pack)[0].length).from('cm').to('in').toFixed(4));
        Object.values(pack)[0].width = (convert(Object.values(pack)[0].width).from('cm').to('in').toFixed(4));
        Object.values(pack)[0].height = (convert(Object.values(pack)[0].height).from('cm').to('in').toFixed(4));
        Object.values(pack)[0].distance_unit = 'in';
        Object.values(pack)[0].mass_unit = 'lb';
        return pack;
      });
      setShippoPackages(updatedShippoPackages);
    }
  };
  //########### handel packing slip change to load packing slip data ####################
  const handlePackingSlipChange = async (e, action) => {
    if (action.action === 'select-option') {
      setPackingSlipValue([e]);
      let desc = [{ description: '' }];
      if (packingSlipList?.length > 0)
        desc = packingSlipList.filter((item, index) => {
          return item._id == e.value;
        })
      setFormData((ev) => ({
        ...ev,
        packingSlipId: e.value,
        description: desc[0]?.description,
      }));
    }
  };

  const groupRatesByProvider = (rate, key) => {
    return rate.reduce((reduceRate, reduceKey) => {
      (reduceRate[reduceKey[key]] = reduceRate[reduceKey[key]] || []).push(reduceKey);
      return reduceRate;
    }, {});
  };

  const onClickEditIcon = (e) => {
    e.preventDefault();
    setEditDeliveryAddressToggle(!editDeliveryAddressToggle);
  }

  const onClickChangeServiceType = (e) => {
    e.preventDefault();
    setEditServiceTypeToggle(!editServiceTypeToggle);
  }

  const onAddressChange = (e) => {
    e.preventDefault();
    navigate(`/admin/order/edit/${currentShippingOrder._id}`);
  }

  const onClickEditDimensionToggle = (e) => {
    setEditDimensionToggle(!editDimensionToggle);
  }

  //########################## On Service Type Change #############################
  const onServiceTypeChange = (shippingData) => {

    let shippingRate = {
      rate: shippingData?.rate_id,
      charges: shippingData?.totalCharges,
      ...shippingData
    }

    setFormData((ev) => ({
      ...ev,
      shippingRate: shippingRate,
    }));

    setShippingMode(shippingData)
  }

  const printShipLable = () => {

    printLabel(match.params.order_id, match.params.shipping_box_id, { ...formData, ...currentShippoDetails, deliveryInstruction }, { ...toAddress }, shippingDimenssionsEdited, deliveryAddressEdited)
      .then((res) => {
        console.log(res);
        if (res.redirect === true) {
          const a = document.createElement('a');
          a.href = res.label_url;
          a.id = 'label_url';
          a.target = '_blank';
          a.rel = "noopener noreferrer";
          document.body.appendChild(a);
          document.querySelector('a[id=label_url]').click();
          const removeanchor = document.getElementById('label_url');
          removeanchor.remove();

          navigate(`/admin/orders/shipping-boxes/${match.params.order_id}`);
        } else {
          navigate(`/admin/orders/shipping-boxes/${match.params.order_id}`);
        }
      });
  }
  //########################## on submit create order ##############################
  const onSubmit = (e) => {
    e.preventDefault();
    //console.log(formData, 'formData');
    printLabel(match.params.order_id, match.params.shipping_box_id, { ...formData, ...currentShippoDetails, deliveryInstruction }, { ...toAddress }, shippingDimenssionsEdited, deliveryAddressEdited)
      .then((res) => {
        console.log(res)
        if (res.redirect === true) {
          const a = document.createElement('a');
          a.href = res.label_url;
          a.id = 'label_url';
          a.target = '_blank';
          a.rel = "noopener noreferrer";
          document.body.appendChild(a);
          document.querySelector('a[id=label_url]').click();
          const removeanchor = document.getElementById('label_url');
          removeanchor.remove();

          navigate(`/admin/orders/shipping-boxes/${match.params.order_id}`);
        } else {
          navigate(`/admin/orders/shipping-boxes/${match.params.order_id}`);
        }
      });
  };

  const {
    address,
    address_line_1,
    address_line_2,
    name,
    email,
    latitute,
    longitude,
    contact_no,
    iute164_phone,
    city,
    zipcode,
    country,
    state,
    // location: geometry,
  } = newAddressData;

  const onSubmitNewAddress = (e) => {
    e.preventDefault();
    setPhoneError("");
    setEmailError("");
    setAddressError("");

    const isEmail = (email) => {
      return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
    };

    if (!isEmail(newAddressData.email)) {
      setEmailError('Invalid Email');
      return;
    }

    if (!newAddressData.contact_no) {
      setPhoneError('Phone Number is required.');
      return;
    }
    if (newAddressData?.contact_no.length < 6 || newAddressData?.contact_no.length > 12) {
      setPhoneError('Invalid contact number ( 6 - 12 digits long recommended )');
      return;
    }
    if (!newAddressData.address_line_1) {
      setAddressError('Search & Select Valid Address');
      return;
    }
    setToAddress((prevState) => {
      return { ...prevState, ...newAddressData, ccode: defaultCountryCode, addressData: newAddressData }
    });

    setEditDeliveryAddressToggle(!editDeliveryAddressToggle);
    setDeliveryAddressEdited(true);
  }

  const onCancelAddingNewAddress = () => {
    setEditDeliveryAddressToggle(!editDeliveryAddressToggle);
    setNewAddressData({ ...toAddress });
    // setToAddress({
    //   country_code: currentShippingOrder?.shipping_country_code,
    //   zipcode: currentShippingOrder?.shipping_zipcode,
    //   city: currentShippingOrder?.shipping_city,
    //   state: currentShippingOrder?.shipping_state,
    //   ccode: currentShippingOrder?.shipping_address?.ccode,
    //   contact_no: currentShippingOrder?.shipping_address?.contact_no,
    //   address: currentShippingOrder?.shipping_address?.address,
    //   addressData: currentShippingOrder?.shipping_address,
    //   name: currentShippingOrder?.shipping_address?.name,
    //   email: currentShippingOrder?.shipping_address?.email,
    //   country: currentShippingOrder?.shipping_country
    // });
    setPhoneError("");
    setEmailError("");
    // setDeliveryAddressEdited(false);
  }

  return (
    shippingOrderLoading || shippingLabelLoading || shippingRatesLoading || shippingOrderRatesLoading || Object.keys(currentShippingOrder).length <= 0 ? <Spinner /> : (
      <>
        <div className="animated fadeIn viewOrderDetailstab">
          <Card>
            <CardHeader>
              <Button className="mr-05" size="sm" color="primary" onClick={printShipLable} disabled={!editDimensionToggle}>
                <i className="fa fa-dot-circle-o"></i> Print
              </Button>
              <Link to={`/admin/orders/shipping-boxes/${match.params.order_id}`}>
                <Button type="button" size="sm" color="danger">
                  <i className="fa fa-arrow-left"></i> Back
                </Button>
              </Link>
            </CardHeader>
          </Card>
          <Card>
            <CardHeader>
              <Label className="label-tag-header">Order Details</Label>
            </CardHeader>
            <CardBody>
              {currentShippingOrder?.products.map((item, index) => (
                <>

                  <Row className="border_bottom">
                    <Col>
                      <Col>

                        <Label className="label-tag-header">
                          {item.product_title} ({item.category_name})
                        </Label>

                        <div>

                          <Label className="label-tag"> SKU : </Label>  {item.sku}
                        </div>
                        <div>
                          <Label className="label-tag"> Quantity :</Label> {item.quantity}
                        </div>
                        <div>
                          {/*  <Label className="label-tag"> Product Price :</Label> ${Number(item.product_price['$numberDecimal'] * Number(item.quantity)).toFixed(2)} */}
                          <Label className="label-tag"> Product Price :</Label> ${Number(item.product_price['$numberDecimal']).toFixed(2)}
                        </div>
                        {(!["Build Shop Supply Product", "Services", "service"].includes(item?.category_name) && Object.keys(item.product_variation).length > 0) && (
                          <div >

                            {Object.entries(item.product_variation).map(
                              ([key, value]) => (
                                <>
                                  <div>
                                    <Label className="label-tag"> {key} :</Label> {value}
                                  </div>
                                </>

                              )
                            )}
                          </div>
                        )}

                        {
                          item.fitting_options &&
                            item.fitting_options.length > 0 ?
                            item.fitting_options.map((value) => {
                              if (value.attribute_name == "Playing Length") {
                                return (
                                  <>
                                    <div>
                                      <Label className="label-tag">  Playing Length :</Label> {(value?.attribute_value == 0) ? "Uncut" : value?.attribute_value}
                                    </div>
                                  </>

                                )
                              }

                              if (value.attribute_name == "Extra Wrap") {
                                return (
                                  <>
                                    <div>
                                      <Label className="label-tag"> Extra Wraps :</Label>
                                      {
                                        value.attribute_value && value.attribute_value == 1 ?
                                          'None'
                                          :
                                          value.attribute_value == 2 ?
                                            '1'
                                            :
                                            value.attribute_value == 3 ?
                                              '2'
                                              :
                                              'None'
                                      }
                                    </div>
                                  </>

                                )
                              }
                              if (value.attribute_name == "Grip Logo") {
                                return (
                                  <>
                                    <div>
                                      <Label className="label-tag"> Grip Logo :</Label>
                                      {value.attribute_value && value.attribute_value}
                                    </div>
                                  </>

                                )
                              }

                              if (value.attribute_name == "Adaptors") {
                                return (
                                  <>
                                    <div>
                                      <Label className="label-tag"> Adaptor :</Label> {value.option_name}  ${value?.price}
                                    </div>
                                    <div>
                                      <Label className="label-tag"> Adaptor Setting : </Label>{value.sub_option_name}
                                    </div>
                                  </>

                                )

                              }
                              if (value.attribute_name == "Grips") {
                                return (
                                  <>
                                    <div>
                                      <Label className="label-tag">  Grips Brand : </Label>{value.option_name}
                                    </div>
                                    <div>
                                      <Label className="label-tag"> Grip Model : </Label>{value.sub_option_name} ${value?.price}
                                    </div>
                                  </>

                                )

                              }
                            })
                            :
                            null
                        }

                      </Col>
                    </Col>
                    <Col>
                      {
                        item.fitting_options &&
                          item.fitting_options.length > 0 ?
                          <>
                            <Label className="label-tag-header">
                              Customization Notes
                            </Label>
                            <div>
                              {
                                item.fitting_options.map((value) => {
                                  if (value.attribute_name == "Product Customization") {
                                    return (
                                      <>
                                        <div>
                                          {value.attribute_value}
                                        </div>
                                      </>

                                    )
                                  }

                                })
                              }
                            </div>
                          </>
                          :
                          <>
                            <Label className="label-tag-header">
                              Customization Notes
                            </Label>
                            <div>NA</div>
                          </>

                      }
                    </Col>
                    <Col>
                      {
                        item.fitting_options &&
                          item.fitting_options.length > 0 ?
                          <>
                            <Label className="label-tag-header">
                              Build Shop Notes
                            </Label>

                            <p>{item.build_shop_notes}</p>
                          </>
                          :
                          null
                      }
                    </Col>
                  </Row>

                </>

              ))}
            </CardBody>
          </Card>
          <Form id="shipLabelForm" className="form-horizontal" onSubmit={(e) => onSubmit(e)}>
            <fieldset>
              <Card>
                <CardHeader className="label-tag_header">
                  <Label className="label-tag-header">Shipping Details</Label>
                </CardHeader>
                <CardBody>
                  <Container fluid className="p-0">
                    <div>
                      <h6 className="heading-flex">Shipping Address {' '}
                        <Button type="button" size="sm" color="success" onClick={(e) => { onClickEditIcon(e) }}>
                          <i className="fa fa-pencil"></i>
                        </Button>
                      </h6>
                    </div>
                    <Row>
                      <Col>
                        <div className="details-view-outer">
                          <Row>
                            <Col md="6" className="data-ptag">
                              <Label className="label-tag">Name :</Label>
                              {toAddress?.name}
                            </Col>
                            <Col md="6" className="data-ptag">
                              <Label className="label-tag">Email :</Label>
                              {toAddress?.email}
                            </Col>
                            <Col md="6" className="data-ptag">
                              <Label className="label-tag">Contact No :</Label>
                              {toAddress?.ccode}{toAddress?.contact_no}
                            </Col>
                            <Col md="6">
                              <AddressFormat
                                address={
                                  {
                                    addressData: toAddress?.addressData,
                                    city: toAddress?.city,
                                    state: toAddress?.state,
                                    zipcode: toAddress?.zipcode,
                                    country: toAddress?.country
                                  }
                                }
                              />
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>

                    <div>
                      <h6 className="heading-flex">Shipping Dimensions {' '}
                        <Button type="button" size="sm" color="success" onClick={(e) => { onClickEditDimensionToggle(e) }}>
                          <i className="fa fa-pencil"></i>
                        </Button>
                      </h6>
                    </div>
                    <div className="details-view-outer">
                      <Row>
                        <Col sm="12">
                          <FormGroup>
                            <Input
                              type="checkbox"
                              id="metric_or_imperial"
                              name="metric_or_imperial"
                              checked={isMetricImperial}
                              className="mr-2"
                              onChange={(e) => handleUnitsCheckedChange(e)}
                            />
                            {`  Metric System / Imperial System(${isMetricImperial
                              ? 'uncheck to convert in Imperial System' : 'check to convert in Metric System'
                              })`}
                          </FormGroup>
                        </Col>
                      </Row>
                      {!shippoPackages.length ? (
                        <Spinner />
                      ) :
                        <FormGroup>
                          {shippoPackages.map((pack, indx) => (


                            <Row key={Object.keys(pack)[0]}>
                              <strong>
                                {/* {shippingDimensionLabel[Object.keys(pack)[0]]} */}
                                Box - {currentShippingOrder?.shipping_box[0]?.box[0]?.name}(KXS-{currentShippingOrder?.shipping_box[0]?.boxId}), {" "}
                                {currentShippingOrder?.products?.map((prod) => {
                                  return `Product - ${prod?.product_title} Quantity - ${prod.quantity}  ,`
                                })}
                              </strong>
                              <Col sm="3">
                                <FormGroup>
                                  <Label htmlFor="package_weight">
                                    Package Weight in "{shippoWeightUnit}"
                                  </Label>
                                  <Input
                                    type="number"
                                    className="form-control"
                                    name="package_weight"
                                    id="package_weight"
                                    value={Object.values(pack)[0].weight}
                                    onChange={(e) => onShipBoxDimensionChange(e, Object.keys(pack)[0])}
                                    required
                                    disabled={editDimensionToggle}
                                    invalid={errorList.package_weight ? true : false}
                                  />
                                  <Errors current_key="package_weight" key="package_weight" />
                                </FormGroup>
                              </Col>
                              <Col sm="3">
                                <FormGroup>
                                  <Label htmlFor="package_length">
                                    Package Length in "{shippoLengthUnit}"
                                  </Label>
                                  <Input
                                    type="number"
                                    className="form-control"
                                    name="package_length"
                                    id="package_length"
                                    value={Object.values(pack)[0].length}
                                    onChange={(e) => onShipBoxDimensionChange(e, Object.keys(pack)[0])}
                                    required
                                    disabled={editDimensionToggle}
                                    invalid={errorList.package_length ? true : false}
                                  />
                                  <Errors current_key="package_length" key="package_length" />
                                </FormGroup>
                              </Col>
                              <Col sm="3">
                                <FormGroup>
                                  <Label htmlFor="package_width">
                                    Package Width in "{shippoLengthUnit}"
                                  </Label>
                                  <Input
                                    type="number"
                                    className="form-control"
                                    name="package_width"
                                    id="package_width"
                                    value={Object.values(pack)[0].width}
                                    onChange={(e) => onShipBoxDimensionChange(e, Object.keys(pack)[0])}
                                    required
                                    disabled={editDimensionToggle}
                                    invalid={errorList.package_width ? true : false}
                                  />
                                  <Errors current_key="package_width" key="package_width" />
                                </FormGroup>
                              </Col>
                              <Col sm="3">
                                <FormGroup>
                                  <Label htmlFor="package_height">
                                    Package Height in "{shippoLengthUnit}"
                                  </Label>
                                  <Input
                                    type="number"
                                    className="form-control"
                                    name="package_height"
                                    id="package_height"
                                    value={Object.values(pack)[0].height}
                                    onChange={(e) => onShipBoxDimensionChange(e, Object.keys(pack)[0])}
                                    required
                                    disabled={editDimensionToggle}
                                    invalid={errorList.package_height ? true : false}
                                  />
                                  <Errors current_key="package_height" key="package_height" />
                                </FormGroup>
                              </Col>
                            </Row>


                          ))}
                          {!editDimensionToggle ?
                            <Button type="button" color="primary" className="mr-05" onClick={onSubmitShippingDimenssions}>
                              Submit
                            </Button> : null}
                          {!editDimensionToggle ?
                            <Button type="button" color="danger" onClick={(e) => onClickCancleDimenssions(e)}>
                              Cancel
                            </Button> : null}
                        </FormGroup>}
                    </div>

                    <div>
                      <h6 className="heading-flex">Service Type {' '}

                        <Button type="button" size="sm" color="success" onClick={(e) => { onClickChangeServiceType(e) }}>
                          <i className="fa fa-pencil"></i>
                        </Button>

                      </h6>
                    </div>
                    <div className="details-view-outer">
                      <Errors current_key="shippingRate" key="shippingRate" />

                      {shippingRatesLoading ? <Spinner /> :
                        (

                          <Row>
                            <Col>
                              {shippingRates.length > 0 ? (
                                Object.entries(groupedRatesByProvider)?.map(([provider, rates]) => {
                                  return (
                                    < div className="shipping-flex">
                                      <Label>
                                        <b>{convertCarrierTypes(provider)}</b>
                                      </Label>{
                                        rates?.map((value, key) => (
                                          <FormGroup key={provider + '-' + key}>
                                            <div className="radio d-flex">
                                              <Input
                                                type="radio"
                                                name="shippingMethod"
                                                value={provider + '-' + key}
                                                checked={value?.service == shippingMode?.service && value?.carrier == shippingMode?.carrier}
                                                disabled={editServiceTypeToggle}
                                                onChange={(e) => { onServiceTypeChange(value) }}
                                                className="mr-2"
                                              />
                                              <Label>
                                                {' '}
                                                {convertServiceTypes(value.service)} {(currentShippingOrder?.shippingServiceCategory === 2 ? '' : ': $' + value.totalCharges)}
                                              </Label>
                                            </div>
                                          </FormGroup>
                                        ))}
                                    </div>)
                                })
                              ) : (
                                <FormGroup>
                                  <div className="radio d-flex">
                                    <Label> {shippingModeError}</Label>
                                  </div>
                                </FormGroup>
                              )}
                              {shippingMessages.length > 0 ? (
                                shippingMessages.map((value, key) => (
                                  <FormGroup key={key}>
                                    <div>
                                      {
                                        shippingMessages[key]?.source !== shippingMessages[key - 1]?.source && <Label><b>{convertCarrierTypes(value.source)}</b>
                                        </Label>
                                      }
                                    </div>
                                    <div className="radio d-flex">
                                      <Label>
                                        {' '}
                                        {value.text}
                                      </Label>
                                    </div>
                                  </FormGroup>
                                ))
                              ) : (null)}
                            </Col>

                          </Row>
                        )}
                      {shippingModeChanged ? <Row>
                        <div>
                          <span mode="warning" >
                            NOTE: Shipping prices has changed from <b>${currentShippingOrder.shipping_mode?.totalCharges + " (" + convertCarrierTypes(currentShippingOrder.shipping_mode?.carrier) + " : " + convertServiceTypes(currentShippingOrder.shipping_mode?.service) + ")"}</b> to <b> ${shippingMode?.totalCharges + " (" + convertCarrierTypes(shippingMode?.carrier) + " : " + convertServiceTypes(shippingMode?.service) + ")"}</b></span>
                        </div>
                      </Row> : null}
                    </div>
                    <Row>
                      <Col sm="6">
                        <FormGroup>
                          <Label htmlFor="deliveryInstruction">
                            Delivery Instructions
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            name="deliveryInstruction"
                            id="deliveryInstruction"
                            maxLength="100"
                            value={deliveryInstruction}
                            onChange={(e) => onChangeDeliveryInstruction(e)}
                            invalid={errorList.deliveryInstruction ? true : false}
                          />
                          <Errors current_key="deliveryInstruction" key="deliveryInstruction" />
                        </FormGroup>
                      </Col>
                    </Row>


                  </Container>
                </CardBody>
              </Card>
              <Card>
                <CardHeader className="label-tag-header">

                  <Label className="label-tag-header">Internal Notes</Label>
                </CardHeader>
                <CardBody>
                  <ViewInternalNotes order_id={match.params.order_id} > </ViewInternalNotes>
                </CardBody>
                <CardFooter>
                  <Button className="mr-05" type="submit" size="sm" color="primary" disabled={!editDimensionToggle}>
                    <i className="fa fa-dot-circle-o"></i> Print
                  </Button>
                  <Link to={`/admin/orders/shipping-boxes/${match.params.order_id}`}>
                    <Button type="button" size="sm" color="danger">
                      <i className="fa fa-arrow-left"></i> Back
                    </Button>
                  </Link>
                </CardFooter>

              </Card>

            </fieldset>
          </Form>
        </div>
        <Modal className="animated fadeIn" isOpen={!editDeliveryAddressToggle} style={{ marginBottom: "20px" }} size={"lg"}>
          <ModalHeader toggle={onClickEditIcon}>Change Address</ModalHeader>
          <Form
            className="form-horizontal"
            onSubmit={(e) => onSubmitNewAddress(e)}
          >
            <ModalHeader>
              <Row>
                <Col xs="12" sm="12">
                  <Card className="mb0">
                    <CardHeader>
                      <h5>Address Information</h5>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col sm="6">
                          <FormGroup>
                            <Label htmlFor="name">
                              Name <span>*</span>
                            </Label>
                            <Input
                              type="text"
                              id="name"
                              name="name"
                              value={name}
                              required
                              onChange={(e) => onChangeNewAddress(e)}
                              invalid={errorList.name ? true : false}
                            />
                            <Errors current_key="name" key="name" />
                          </FormGroup>
                        </Col>
                        <Col sm="6">
                          <FormGroup>
                            <Label htmlFor="address">Search Your Address</Label>
                            <Autocomplete
                              apiKey={GOOGLE_MAPS_API_KEY}
                              onPlaceSelected={(place) => {
                                {
                                  setGMapPlace(place);
                                }
                              }}
                              options={{
                                types: ['address'],
                              }}
                              value={autocompleteValue}
                              onChange={handleInputChange}
                              className="form-control"
                              onFocus={() => setGoogleAddressFormData(newAddressData)}
                            />
                            {addressError && <p style={{ color: "red", fontSize: "14px", fontWeight: "300" }}>{addressError}</p>}
                          </FormGroup>
                        </Col>
                        <Col sm="6">
                          <FormGroup>
                            <Label htmlFor="address">
                              Address <span>*</span>
                            </Label>
                            <Input
                              type="textarea"
                              id="address"
                              name="address"
                              value={address}
                              required
                              readOnly
                              disabled={true}
                              onChange={(e) => onChangeNewAddress(e)}
                              invalid={errorList.address ? true : false}
                            />
                            <Errors current_key="address" key="address" />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <Label htmlFor="address">
                              Address Line 1<span>*</span>
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="address_line_1"
                              name="address_line_1"
                              placeholder="Address Line 1 *"
                              maxLength="100"
                              value={address_line_1}
                              required
                              disabled={true}
                              onChange={(e) => onChangeNewAddress(e)}
                              invalid={errorList.address_line_1 ? true : false}
                            />
                            <Errors
                              current_key="address_line_1"
                              key="address_line_1"
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <Label htmlFor="address">Address Line 2</Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="address_line_2"
                              name="address_line_2"
                              placeholder="Address Line 2"
                              maxLength="100"
                              value={address_line_2}
                              onChange={(e) => onChangeNewAddress(e)}
                              invalid={errorList.address_line_2 ? true : false}
                            />
                            <Errors
                              current_key="address_line_2"
                              key="address_line_2"
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="6">
                          <FormGroup>
                            <Label htmlFor="country">
                              Country <span>*</span>
                            </Label>
                            <Input
                              type="text"
                              name="country"
                              id="country"
                              value={country}
                              disabled={true}
                              required
                              invalid={errorList.country ? true : false}
                            ></Input>
                            <Errors current_key="country" key="country" />
                          </FormGroup>
                        </Col>
                        <Col sm="6">
                          <FormGroup>
                            <Label htmlFor="state">State</Label>
                            <Input
                              type="text"
                              name="state"
                              id="state"
                              value={state}
                              disabled={true}
                              invalid={errorList.state ? true : false}
                            />
                            <Errors current_key="state" key="state" />
                          </FormGroup>
                        </Col>
                        <Col sm="6">
                          <FormGroup>
                            <Label htmlFor="city">
                              City <span>*</span>
                            </Label>
                            <Input
                              type="text"
                              id="city"
                              name="city"
                              maxLength="100"
                              value={city}
                              disabled={true}
                              required
                              invalid={errorList.city ? true : false}
                            />
                            <Errors current_key="city" key="city" />
                          </FormGroup>
                        </Col>
                        <Col sm="6">
                          <FormGroup>
                            <Label htmlFor="zipcode">
                              Zipcode <span>*</span>
                            </Label>
                            <Input
                              type="text"
                              id="zipcode"
                              name="zipcode"
                              maxLength="10"
                              value={zipcode}
                              required
                              disabled={true}
                              invalid={errorList.zipcode ? true : false}
                            />
                            <Errors current_key="zipcode" key="zipcode" />
                          </FormGroup>
                        </Col>
                        <Col sm="6">
                          <FormGroup>
                            <Label htmlFor="email">
                              Email <span>*</span>
                            </Label>
                            <Input
                              type="text"
                              id="email"
                              name="email"
                              value={email}
                              required
                              onChange={(e) => onChangeNewAddress(e)}
                              invalid={errorList.email ? true : false}
                            />
                            {emailError && <p style={{ color: "red", fontSize: "14px", fontWeight: "300" }}>{emailError}</p>}
                            <Errors current_key="email" key="email" />
                          </FormGroup>
                        </Col>
                        <Col sm="6">
                          <FormGroup>
                            <Label htmlFor="contact_no">
                              Contact No <span>*</span>
                            </Label>
                            <PhoneInput
                              className=""
                              autoComplete="tel"
                              withCountryCallingCode={true}
                              countryCallingCodeEditable={false}
                              smartCaret={true}
                              international={true}
                              defaultCountry={defaultCountry}
                              value={iute164_phone}
                              name="contact_no"
                              addInternationalOption={false}
                              onChange={onPhoneChange}
                              onCountryChange={onCountryChange}
                              required
                            />
                            {phoneError && <p style={{ color: "red", fontSize: "14px", fontWeight: "300" }}>{phoneError}</p>}
                            <Errors current_key="contact_no" key="contact_no" />
                          </FormGroup>
                        </Col>

                      </Row>
                    </CardBody>

                  </Card>
                </Col>
              </Row>
            </ModalHeader>
            <ModalFooter>
              <CardFooter>
                <Button
                  className="mr-05"
                  type="submit"
                  size="sm"
                  color="primary"
                  style={{ marginRight: "10px" }}
                >
                  <i className="fa fa-dot-circle-o"></i> Submit
                </Button>

                <Button type="button" size="sm" color="danger" onClick={onCancelAddingNewAddress}>
                  <i className="fa fa-ban"></i> Cancel
                </Button>

              </CardFooter>
            </ModalFooter>
          </Form>
        </Modal>
      </>)
  );
};

ShippingLabel.propTypes = {
  printLabel: PropTypes.func.isRequired,
  getPackingSlipList: PropTypes.func.isRequired,
  getShippingOrderById: PropTypes.func.isRequired,
  packingSlipList: PropTypes.array.isRequired,
  packingSlipLoading: PropTypes.bool.isRequired,
  packingSlipSubLoading: PropTypes.bool.isRequired,
  currentShippingOrder: PropTypes.object.isRequired,
  shippingOrderLoading: PropTypes.bool.isRequired,
  errorList: PropTypes.array.isRequired,
  calculateShippingRatesForLabelPrint: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  shippingRatesLoading: PropTypes.bool.isRequired,
  shippingLabelLoading: PropTypes.bool.isRequired,
  setGoogleAddressFormData: PropTypes.func.isRequired
  // edit: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  packingSlipList: state.packingSlip.packingSlipList.data,
  packingSlipLoading: state.packingSlip.loading,
  packingSlipSubLoading: state.packingSlip.isSubLoading,
  currentShippingOrder: state.order.currentShippingOrder,
  shippingOrderLoading: state.order.shippingOrderLoading,
  shippingRatesLoading: state.order.labelPrintShippingRatesLoading,
  shippingOrderRatesLoading: state.order.shipping_rates_loading,
  shippingLabelLoading: state.order.orderLabelLoading,
  errorList: state.errors,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  printLabel,
  getPackingSlipList,
  getShippingOrderById,
  calculateShippingRatesForLabelPrint,
  setGoogleAddressFormData
})(ShippingLabel);
